import React from 'react'
import { FormattedMessage } from 'react-intl'

export const links = [
	{
		id:    0,
		title: <FormattedMessage id="info" />,
		to:    '/about',
		font:  {
			fontSize:    '14px',
			paddingLeft: '2px',
		},
	},
	{
		id:    2,
		title: <FormattedMessage id="privacy_policy" />,
		to:    '/police',
		font:  {
			fontSize:    '14px',
			paddingLeft: '2px',
		},
	},
]
export const linksLogOut = [
	{
		id:    0,
		title: <FormattedMessage id="info" />,
		to:    '/about',
		font:  {
			fontSize:    '14px',
			paddingLeft: '0px',
		},
	},
	{
		id:    2,
		title: <FormattedMessage id="privacy_policy" />,
		to:    '/police',
		font:  {
			fontSize:    '14px',
			paddingLeft: '0px',
		},
	},
]
export const userLinks = [
	{
		id:    1,
		icon:  'favoriteIcon',
		title: <FormattedMessage id="follow" />,
		to:    0,
	},
	{
		id:    2,
		icon:  'takingIcon',
		title: <FormattedMessage id="take" />,
		to:    1,
	},
	{
		id:    3,
		icon:  'returnIcon',
		title: <FormattedMessage id="give" />,
		to:    2,
	},
	{
		id:    4,
		icon:  'promoPercent',
		title: <FormattedMessage id="lot_type_promotion" />,
		to:    3,
		font:  {
			paddingLeft:    '12px',
		},
	},
]
