import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { ITEMS_ACTION } from './actionTypes'

export const ItemsActions = {
	getItem:            RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEM),
	getItems:           RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS),
	getSpacerItems:     RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_SPACER_ITEMS),
	getSliderItems:     RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_SLIDER_ITEMS),
	getMoreItems:       RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_MORE_ITEMS),
	setItems:           RequestActionHelper.getActionCreatorSuccess(ITEMS_ACTION.GET_ITEMS),
	likeItem:           RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.LIKE_ITEM),
	getItemsQuantity:   RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_QUANTITY),
	getItemsCount:      RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_COUNT),
	getItemsBet:        RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_BET),
	getItemsCreate:     RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_CREATE),
	getItemsPromo:      RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_PROMO),
	getItemsFavorite:   RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_ITEMS_FAVORITE),
	getSearchItems:     RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.SEARCH_ITEMS),
	getSearchTags:      RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.SEARCH_TAGS),
	getLotInfo:         RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.GET_LOT_INFO),
	updateItem:         RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.UPDATE_ITEM),
	getMoreSearchItems: RequestActionHelper.getActionCreatorFetch(ITEMS_ACTION.SEARCH_MORE_ITEMS),
}
