import { callApi, callApiEndpoint, getAccessToken } from '../../api'

const login = searchQuery => {
	searchQuery
		.addRequestOptions({
			method: 'POST',
		}, true)
		.addCustomParams({
			saveAuth: true,
		}, true)

	return callApi('/user/login', searchQuery)
}

const logout = searchQuery => {
	searchQuery.addRequestOptions({
		method: 'POST',
	}, true)
	searchQuery.addBody({ access_token: getAccessToken() })

	return callApi('/user/logout', searchQuery)
}

const renewToken = searchQuery => {
	searchQuery.addRequestOptions({
		method: 'POST',
	}, true)
	return callApiEndpoint('/user/refresh-token', searchQuery.getRequestOptions(), searchQuery.getCustomParams())
}

export const AuthApi = {
	login,
	logout,
	renewToken,
}
