import { INTL_ACTIONS } from './actionTypes'

export const setLang = payload => ({
	type: INTL_ACTIONS.SET_LANG,
	payload,
})

export const setLangMessages = payload => ({
	type: INTL_ACTIONS.SET_LANG_MESSAGES,
	payload,
})

export const setReload = payload => ({
	type: INTL_ACTIONS.SET_IS_RELOAD,
	payload,
})
