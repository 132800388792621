import { callApi } from '../../api'

const getUser = searchQuery => callApi('/user/profile', searchQuery)

const createDeepLink = searchQuery => {
	searchQuery.addRequestOptions({ method: 'POST' })
	return callApi('/user/create-deeplink', searchQuery)
}
const getUserUpDate = searchQuery => {
	searchQuery.addRequestOptions({
		method: 'PATCH',
	}, true)
	return callApi('/user/update', searchQuery)
}
export const UserApi = {
	getUser,
	createDeepLink,
	getUserUpDate,
}
