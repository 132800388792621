import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import { MODAL_SUBSCRIPTION, QUERY_PARAMS, SCROLL, SEARCH_STRING, CITY } from './actionTypes'

const INIT_STATE = {
	isSubscription: false,
	searchString:   '',
	isScroll:       false,
	queryParams:    {
		lat:          53.900601,
		lon:          27.558972,
		category:     0,
		filter:       0,
		sort:         9,
		distance:     10,
		city:         'Минск',
		country_code: 'BY',
		cityAlias:    '',
	},
	city: ''
}

const baseReducer =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case MODAL_SUBSCRIPTION: {
			return {
				...state,
				isSubscription: action.isSubscription,
			}
		}
		case SEARCH_STRING: {
			return {
				...state,
				searchString: action.searchString,
			}
		}
		case SCROLL: {
			return {
				...state,
				isScroll: action.isScroll,
			}
		}
		case QUERY_PARAMS: {
			return {
				...state,
				queryParams: { ...state.queryParams, ...action.params },
			}
		}
		case CITY: return Object.assign({}, state, {
			city: action.payload,
		})
		default:
			return { ...state }
	}
}
const persistConfig = {
	key: 'base',
}

export default PersistHelper.persist(persistConfig, baseReducer)
