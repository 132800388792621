import React from 'react'
import PropTypes from 'prop-types'
import photoIcon from 'assets/images/photoIcon.svg'
import { useIntl } from 'react-intl'
import LanguagesSwitcher from '../../../../../../global/LanguagesSwitcher/index.store'
import styles from './styles.module.scss'
import AsideLinks from '../../../../../../global/AsideLinks'
import { linksLogOut } from '../../../../../../global/AsideLinks/fields'
import { LoginButton } from '../../LoginButton'

export const AsidePanel = ({ onClose }) => {
	const intl = useIntl()
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.photo}>
					<img src={photoIcon} alt="" />
				</div>
				<div className={styles.login}>
					<LoginButton textButton={intl.formatMessage({ id: 'login' })} className={styles.loginButton} />
					<LoginButton
						textButton={intl.formatMessage({ id: 'karma_up' })}
						className={styles.addKarma}
					/>
					<LoginButton
						textButton={intl.formatMessage({ id: 'subscriptions_enable' })}
						className={styles.addKarma}
					/>
				</div>
			</div>
			<LanguagesSwitcher setIsOpenDrawer={onClose} />
			<AsideLinks links={linksLogOut} onClose={onClose} />
		</div>
	)
}

AsidePanel.propTypes = {
	onClose: PropTypes.func.isRequired,
}
