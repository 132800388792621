import * as PropTypes from 'prop-types'
import React from 'react'
import QRcode from 'assets/images/QRcode.svg'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

export const GrCode = ({ className }) => {
	const intl = useIntl()
	return (
		<div className={`${styles.qrCode}${' '}${className}`}>
			<div>
				<img src={QRcode} alt="QRcode" />
			</div>
			<span className={[intl.locale === 'en' ? styles.text : '', styles.textQr].join(' ')}>
				{intl.formatMessage({ id: 'download_app' })}
			</span>
		</div>

	)
}
GrCode.propTypes = {
	className: PropTypes.string,
}
GrCode.defaultProps = {
	className: {},
}
