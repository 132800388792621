import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import appStoreButton from '../../../assets/images/appStoreButton.svg'
import googlePlayButton from '../../../assets/images/googlePlayButton.svg'
import Huawei from '../../../assets/images/Huawei.svg'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import Icon from '../Icon'
import { GrCode } from '../QrCode'
import styles from './styles.module.scss'

export const ShowPromoMoneyDialog = ({
	setIsOpen,
	isLotMoney,
	isOpen,
	message,
	isLotMoneyAuction,
	isUserLot,
	lotImg,
	isPromoActive,
}) => {
	const { isMobile } = useDeviceDetect()
	const intl = useIntl()
	const onClose = () => setIsOpen(false)

	const setText = () => {
		if (isUserLot) {
			return `${intl.formatMessage({ id: 'promote_lot_app' })}
		 ${intl.formatMessage({ id: 'in_app' })}`
		}
		if (message) {
			return `${intl.formatMessage({ id: 'question_user_app' })}
		 ${intl.formatMessage({ id: 'in_app' })}`
		}
		if (isLotMoney || isLotMoneyAuction) {
			return `${intl.formatMessage({ id: 'offer_price_app' })}
		 ${intl.formatMessage({ id: 'in_app' })}`
		}
		if (isPromoActive) {
			return `${intl.formatMessage({ id: 'use_promocode' })}
		 ${intl.formatMessage({ id: 'in_app' })}`
		}
		return `${intl.formatMessage({ id: 'take_promo_code' })}
		 ${intl.formatMessage({ id: 'in_app' })}`
	}

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					overflowY:       'unset',
					maxWidth:        '840px',
					boxShadow:       'none',
				},
			}}
		>
			<div className={styles.wrapper}>
				{!isMobile &&
					<GrCode className={styles.qrCodeDownloadPickUp} />}
				<div className={styles.buttonClose}>
					<button type="button" onClick={onClose}>
						<Icon icon="closeIcon" size="24px" color="rgba(43, 43, 43, 0.6)" />
					</button>
				</div>
				<div
					className={styles.image}
					 style={{
						 backgroundImage:      `url(${lotImg})`,
						 backgroundRepeat:     'no-repeat',
						 backgroundPosition:   'center',
						 backgroundSize:       'cover',
						 borderTopRightRadius: '16px',
						 borderTopLeftRadius:  '16px',
					 }}
				/>
				<div className={styles.title}>{setText()}</div>
				<div className={styles.subTittle}>
					{intl.formatMessage({ id: 'use_app' })}
				</div>
				<div className={styles.btnGroup}>
					<div>
						<button type="button">
							<a href="https://play.google.com">
								<img alt="googlePlay" src={googlePlayButton} className={styles.imgButton} />
							</a>
						</button>
					</div>

					<div>
						<button type="button">
							<a href="http://appstore.com">
								<img alt="appStore" src={appStoreButton} className={styles.imgButton} />
							</a>
						</button>
					</div>
					<div>
						<a href="http://appstore.com">
							<button type="button" className={styles.imgButtonHuawei}>
								<img alt="Huawei" src={Huawei} />
							</button>
						</a>
					</div>
				</div>
			</div>
		</Dialog>
	)
}
ShowPromoMoneyDialog.propTypes = {
	setIsOpen:         PropTypes.func,
	isLotMoney:        PropTypes.bool,
	isOpen:            PropTypes.bool,
	message:           PropTypes.bool,
	isLotMoneyAuction: PropTypes.bool,
	isUserLot:         PropTypes.bool,
	isPromoActive:     PropTypes.bool,
	lotImg:            PropTypes.string,
}

ShowPromoMoneyDialog.defaultProps = {
	setIsOpen:         () => null,
	isLotMoney:        false,
	isOpen:            false,
	message:           false,
	isLotMoneyAuction: false,
	isUserLot:         false,
	isPromoActive:     false,
	lotImg:            '',
}
