export const ITEMS_ACTION = {
	GET_ITEM:            'GET_ITEM',
	GET_ITEMS:           'GET_ITEMS',
	GET_SPACER_ITEMS:    'GET_SPACER_ITEMS',
	GET_SLIDER_ITEMS:    'GET_SLIDER_ITEMS',
	GET_MORE_ITEMS:      'GET_MORE_ITEMS',
	LIKE_ITEM:           'LIKE_ITEM',
	GET_ITEMS_QUANTITY:  'GET_ITEMS_QUANTITY',
	GET_ITEMS_COUNT:     'GET_ITEMS_COUNT',
	GET_ITEMS_BET:       'GET_ITEMS_BET',
	GET_ITEMS_CREATE:    'GET_ITEMS_CREATE',
	GET_ITEMS_PROMO:     'GET_ITEMS_PROMO',
	GET_ITEMS_FAVORITE:  'GET_ITEMS_FAVORITE',
	SEARCH_ITEMS:        'SEARCH_ITEMS',
	SEARCH_MORE_ITEMS:   'SEARCH_MORE_ITEMS',
	SEARCH_TAGS:         'SEARCH_TAGS',
	GET_LOT_INFO:        'GET_LOT_INFO',
	UPDATE_ITEM:         'UPDATE_ITEM',
}
