import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import { QUERY_PARAMS_ACTIONS } from 'reduxm/redux-saga/modules/queryParams/actionTypes'


const INIT_STATE = {
	location: {
		lat:  53.900601,
		lon:  27.558972,
	},
	category: 0,
	filter:   0,
	sorting:  9,
	distance: 10,
	city:     {
		name: 'Минск',
		alias: 'minsk'
	},
	// country_code: 'BY',
	// cityAlias:    '',
}

const queryParamsReducer =  (state = INIT_STATE, action) => {
	switch (action.type) {

		case QUERY_PARAMS_ACTIONS.SET_QUERY_LOCATION: {
			return Object.assign({}, state, {
				location: action.payload,
			})
		}

		case QUERY_PARAMS_ACTIONS.SET_QUERY_CATEGORY:
			return Object.assign({}, state, {
				category: action.payload,
			})

		case QUERY_PARAMS_ACTIONS.SET_QUERY_FILTER:
			return Object.assign({}, state, {
				filter: action.payload,
			})

		case QUERY_PARAMS_ACTIONS.SET_QUERY_SORTING:
			return Object.assign({}, state, {
				sorting: action.payload,
			})

		case QUERY_PARAMS_ACTIONS.SET_QUERY_DISTANCE:
			return Object.assign({}, state, {
				distance: action.payload,
			})

		case QUERY_PARAMS_ACTIONS.SET_QUERY_CITY:
			return Object.assign({}, state, {
				city: action.payload,
			})

		default: return { ...state }
	}
}

const persistConfig = {
	key: 'queryParams',
}

export default PersistHelper.persist(persistConfig, queryParamsReducer)
