import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'
import Panel from './Panel/index.store'
import SearchForm from './SearchForm/index.store'
import Logo from '../../global/Logo'

export const Header = () => {
	const intl = useIntl()
	return (
		<header className={[styles.container, 'mui-fixed'].join(' ')}>
			<div className={styles.wrapper}>
				<Link to="/" className={styles.homeLink}>
					<Logo size="25px" />
					<div className={styles.title}>
						{intl.formatMessage({ id: 'give_away' })}
					</div>
				</Link>
				<SearchForm />
				<Panel />
			</div>
		</header>
	)
}
