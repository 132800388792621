import React, { useCallback, useState } from 'react'
import { Dialog, Drawer } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDeviceDetect } from '../../../../../../helpers/useDeviceDetect'
import { getAccessToken } from '../../../../../../reduxm/redux-saga/api'
import LanguagesSwitcher from '../../../../../global/LanguagesSwitcher/index.store'
import { NotificationButton } from '../../../../../global/Notificationbutton'
import UserBlock from '../../../../../global/UserBlock/index.store'
import styles from './styles.module.scss'
import AsideLinks from '../../../../../global/AsideLinks'
import { links, userLinks } from '../../../../../global/AsideLinks/fields'

export const UserButton = ({
	user,
	onLogout,
	setSearchString,
	setUser,
}) => {
	const intl = useIntl()
	const history = useHistory()
	const { isLessIPadMini } = useDeviceDetect()
	const [isOpenDrawer, setIsOpenDrawer] = useState(false)
	const [isOpenDialog, setIsOpenDialog] = useState(false)

	const onCloseDrawer = useCallback(() => {
		setIsOpenDrawer(false)
	}, [])

	const onCloseDialog = useCallback(() => {
		setIsOpenDialog(false)
	}, [])

	const onOpenDialog = useCallback(() => {
		setIsOpenDrawer(false)
		setIsOpenDialog(true)
	}, [])

	const handleAvatarButton = () => {
		const token = getAccessToken()
		if (!token) {
			setUser(null)
			history.push('/')
		}
		setSearchString('')
		setIsOpenDrawer(true)
	}

	return (
		<>
			<div className={[styles.photoContainer, isLessIPadMini && styles.avatarPosition].join(' ')}>
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button
					type="button"
					onClick={handleAvatarButton}
					className={styles.userAvatar}
					style={{ backgroundImage: `url(${user.getAvatar()})` }}
				/>
			</div>
			<Drawer anchor="right" open={isOpenDrawer} onClose={onCloseDrawer}>
				<div className={styles.wrapper}>
					<UserBlock
						user={user}
						className={styles.userBlock}
						onCloseDrawer={onCloseDrawer}
						isOpenDrawer={isOpenDrawer}
					/>
					<AsideLinks links={userLinks} onClose={onCloseDrawer} isUserLinks />
					<div className={styles.notifications}>
						<NotificationButton
							label={intl.formatMessage({ id: 'notifications' })}
						/>
					</div>
					<LanguagesSwitcher setIsOpenDrawer={setIsOpenDrawer} />
					<AsideLinks links={links} onClose={onCloseDrawer} />
					<div className={styles.logout}>
						<button type="button" onClick={onOpenDialog}>
							<span>{intl.formatMessage({ id: 'logout' })}</span>
						</button>
					</div>
				</div>
			</Drawer>
			<Dialog open={isOpenDialog} onClose={onCloseDialog}>
				<div className={styles.dialogWrapper}>
					<div className={styles.dialogTittle}>
						<span>{intl.formatMessage({ id: 'logout' })}</span>
					</div>
					<div className={styles.dialogReally}>
						<span>{intl.formatMessage({ id: 'confirm_logout' })}</span>
					</div>
					<div className={styles.dialogButtonGroup}>
						<button
							className={styles.dialogButtonCancel}
							type="button"
							onClick={onCloseDialog}
						>
							{intl.formatMessage({ id: 'cancel' })}
						</button>
						<button
							className={styles.dialogButtonAccept}
							type="button"
							onClick={onLogout}
						>
							{intl.formatMessage({ id: 'yes' })}
						</button>
					</div>
				</div>
			</Dialog>
		</>
	)
}
UserButton.propTypes = {
	user:            PropTypes.object,
	onLogout:        PropTypes.func,
	setSearchString: PropTypes.func,
	setUser:         PropTypes.func,
}
UserButton.defaultProps = {
	user:            {},
	onLogout:        () => null,
	setSearchString: () => null,
	setUser:         () => null,
}
