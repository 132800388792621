import React from 'react'
import PropTypes from 'prop-types'
import AddKarmaButton from '../AddKarmaButton/index.store'
import { DownloadAppButton, textString } from '../DownloadAppButton'
import styles from './styles.module.scss'
import { UserRating } from '../UserRating'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'

export const UserBlock = ({
	user,
	className,
	setModalSubscription,
	isSubscription,
	userBlockExtended,
	onCloseDrawer,
	isOpenDrawer,
}) => {
	const { isMobile, isLessIPadMini } = useDeviceDetect()
	return (
		<div className={`${styles.wrapper} ${className}`}>
			<div className={styles.userContainer}>
				<div
					className={styles.photo}
					style={{ backgroundImage: `url(${user.getAvatar()})` }}
				/>
				<div className={styles.userRating}>
					<span>{user.getUsername()}</span>
					<UserRating rating={user.getRating()} className={styles.rating} />
				</div>
			</div>
			<div className={(!isMobile && userBlockExtended) && styles.blockExtended}>
				<AddKarmaButton
					size={40}
					buttonPosition="right"
					karma={user.getKarma()}
					isSubscription={isSubscription}
					setModalSubscription={setModalSubscription}
					isOpenDrawer={isOpenDrawer}
					onCloseDrawer={onCloseDrawer}
				/>
				<AddKarmaButton
					size={32}
					buttonPosition="subscription"
					karma={user.getKarma()}
					isSubscription={isSubscription}
					setModalSubscription={setModalSubscription}
					isOpenDrawer={isOpenDrawer}
					onCloseDrawer={onCloseDrawer}
				/>
				{isLessIPadMini && isOpenDrawer &&
				<DownloadAppButton
					obj={textString.addLot}
					className={styles.buttonAddKarma}
					isMobile={isMobile}
				/>}
			</div>
		</div>
	)
}
UserBlock.propTypes = {
	user:                 PropTypes.object,
	className:            PropTypes.string,
	isSubscription:       PropTypes.bool,
	isOpenDrawer:         PropTypes.bool,
	setModalSubscription: PropTypes.func,
	onCloseDrawer:        PropTypes.func,
	userBlockExtended:    PropTypes.string,
}
UserBlock.defaultProps = {
	user:                 {},
	className:            {},
	isSubscription:       false,
	isOpenDrawer:         false,
	userBlockExtended:       '',
	setModalSubscription: () => {},
	onCloseDrawer:        () => {},
}
