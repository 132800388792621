import React from 'react'
import { asyncComponent } from '@jaredpalmer/after'
import NotFound from "containers/NotFound"

const staticPagesList = [
	'about',
	'faq',
	'safely',
	'police',
	'subscriptions',
].join('|')

const routes = [
	{
		path: '/',
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/Home/index.store'),
			chunkName: 'home',
		}),
	},
	{
		path: '/im',
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/UserPage/index.store'),
			chunkName: 'im'
		}),
	},
	{
		path: `/:pageAlias(${staticPagesList})`,
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/StaticPage/index.store'),
			Placeholder: () => <div>...qqq...</div>, // this is optional, just returns null by default
			chunkName: 'static-pages'
		}),
	},
	{
		path: '/:city',
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/Home/index.store'),
			chunkName: 'home',
		}),
	},
	{
		path: '/:city/:category',
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/Home/index.store'),
			chunkName: 'home',
		}),
	},
	{
		path: '/:city/:category/:filter',
		exact: true,
		component: asyncComponent({
			loader: () => import('containers/Home/index.store'),
			chunkName: 'home',
		}),
	},
	{
		path: '/:city/:category/:filter/:id',
		exact: true,
		component: asyncComponent({
			loader: () => import('../containers/ItemPage/index.store'),
			chunkName: 'lot',
		}),
	},
	{
		component: NotFound,
	}
]

export default routes
