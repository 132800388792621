import ProfilingService from '@kakadu-dev/base-frontend-helpers/services/ProfilingService'
import axios from 'axios'
import { defaultLang } from 'constants'
import { merge, pick } from 'lodash'
import {
	delay,
	put,
} from 'redux-saga/effects'
import { CacheHelper } from '@kakadu-dev/base-frontend-helpers/helpers/CacheHelper'
import {
	TYPE,
} from '@kakadu-dev/base-frontend-helpers/helpers/Client'
import {
	deleteCookie,
	getCookie,
	setCookie,
} from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SearchQuery from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider/SearchQuery'
import { AuthService } from '@kakadu-dev/base-frontend-helpers/services/AuthService'
import {
	API_URL,
} from '../config'
import { AuthActions } from '../modules/auth'
import { AuthApi } from '../modules/auth/api'


/**
 * Get jwt access token
 * @param {String} url
 * @param {any} auth
 *
 * @return {string}
 */

const getCacheKey = (url, auth) => {
	return `${url}:::${auth}`
}

/**
 * Get jwt access token
 *
 * @return {string}
 */
export function getAccessToken() {
	return getCookie('accessToken')
}

/**
 * Get jwt refresh token
 *
 * @return {string}
 */
function getRefreshToken() {
	return getCookie('refreshToken')
}

/**
 * Save auth tokens
 *
 * @param {object} body
 *
 * @return {undefined}
 */
export async function saveAuthTokens(body) {
	const { access_token, refresh_token } = body || {}
	if (!access_token || !refresh_token) {
		return false
	}

	return Promise.all([
		setCookie('accessToken', access_token, true),
		setCookie('refreshToken', refresh_token, true),
	])
}

/**
 * Remove auth tokens
 *
 * @return {undefined}
 */
export function removeAuthTokens() {
	return Promise.all([
		deleteCookie('accessToken'),
		deleteCookie('refreshToken'),
	])
}

/**
 * Get full request
 *
 * @param {string} endpoint
 * @param {object} options
 * @param {object} customParams
 *
 * @return {Promise<{response: {response: Response, json: any}} | {error: (*|string)}>}
 */
export async function callApiEndpoint(endpoint, options, customParams = {}) {
	const pld = {}
	ProfilingService.run('beforeRequest', {
		endpoint,
		dataProvider: {
			getBody(){ return { method: endpoint } }
		},
		pld
	})

	const { cacheResponse, saveAuth } = customParams

	// Complete url address
	const fullUrl = (endpoint.indexOf('http') === -1) ? (API_URL + endpoint) : endpoint
	const lang = getCookie('locale') || defaultLang

	// Default request headers
	const defaultOptions = {
		method:  'GET',
		headers: {
			Accept:            '*/*',
			'Content-Type':    'application/json',
			Client:            TYPE,
			'App-Hash':        'web',
	        'App-Version':     10,
	        'Device-Language': lang,
		},
	}

	// Merge default headers with custom headers
	const requestOptions = merge(defaultOptions, options)

	if (requestOptions.body) {
		requestOptions.body = JSON.stringify(requestOptions.body)
	}

	const cacheKey = getCacheKey(fullUrl, options?.headers?.Authorization || 'guest' )

	if (cacheResponse) {
		const cachedResult = await CacheHelper.getItem(cacheKey, 'fetch', cacheResponse)

		if (cachedResult) {
			ProfilingService.run('beforeReturnResponse', {
				endpoint,
				dataProvider: {
					getBody(){ return {method: endpoint } }
				},
				cached: true,
				pld
			})
			return cachedResult
		}
	}

	let body = null
	let error = null
	let response = {}

	try {
		response = await axios.request({
			url: fullUrl,
			...requestOptions
		})
		body = response.data
	}catch (e) {
		error = e
		response = e.response || {
			status: 502
		}
	}

	const result = {
		result: body,
		error,
		response,
	}


	if (cacheResponse && error === null) {
		CacheHelper.setItem(cacheKey, { ...result,
			response: pick(['status', 'statusText', 'headers'])
		}, cacheResponse, 'fetch')
	}

	// Save new tokens
	if (saveAuth && body?.access_token && body?.refresh_token) {
		await saveAuthTokens(body)

		AuthService
			.getInstance()
			.setSeamlessLogin(false)
			.setSeamlessError(false)
	}

	ProfilingService.run('beforeReturnResponse', {
		endpoint,
		dataProvider: {
			getBody(){ return {method: endpoint } }
		},
		cached: false,
		pld
	})
	return result
}

/**
 * Repeat call api request
 * Wait done seamless login and run request
 *
 * @param {string} endpoint
 * @param {object} options
 * @param {number} attempts
 *
 * @return {IterableIterator<*>}
 */
function* repeatRequest(endpoint, options, attempts = 1) {
	yield delay(1500)

	if (AuthService.getInstance().getIsSeamlessLogin() && attempts < 5) {
		return repeatRequest(endpoint, options, attempts + 1)
	}

	if (AuthService.getInstance().getSeamlessLoginError()) {
		// Cancel request
		return false
	}

	return yield callApi(endpoint, options)
}

const toQuery = params => {
	const queryString = Object.entries(params).map(part => part.join('=')).join('&')
	return queryString?.length > 0 ? `?${queryString}` : ''
}

/**
 * Make request and preprocessing response
 *
 * @param {string} endpoint
 * @param {SearchQuery|object} options
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
export function* callApi(endpoint, options) {
	if (AuthService.getInstance().getIsSeamlessLogin()) {
		return yield repeatRequest(endpoint, options)
	}

	const dataProvider = (options instanceof SearchQuery) ?
		options : DataProvider.buildQuery().addRequestOptions(options)

	// Remove end slash if exist and add get params
	const resultEndpoint = endpoint.replace(/\/$/, '') + toQuery(dataProvider.getQueryParams())
	const accessToken = yield getAccessToken()
	const customParams = dataProvider.getCustomParams()
	const lng = getCookie('locale') || defaultLang
	// Add jwt access token to headers if exist
	if (accessToken) {
		dataProvider.addRequestOptions({
			headers: {
				Authorization:     `Bearer ${accessToken}`,
				'App-Hash':        'web',
				'App-Version':     10,
				'Device-Language': lng,
			},
		}, true)
	}

	const result = yield callApiEndpoint(resultEndpoint, dataProvider.getRequestOptions(), customParams)

	// Return only request options, skip fetch
	if (customParams.returnRequest) {
		return result
	}

	if (result.error) {
		const { result: error, response } = result

		const statusCode = Number(response.status)

		let resultError = error

		// Try seamless login (access token expired)
		if (statusCode === 401 && accessToken) {
			if (AuthService.getInstance().getIsSeamlessLogin()) {
				return yield repeatRequest(endpoint, options)
			}

			AuthService.getInstance().setSeamlessLogin(true)
			const seamlessResult = yield renewToken(dataProvider, resultEndpoint)

			const { result: seamlessError, response: seamlessResponse } = seamlessResult

			AuthService
				.getInstance()
				.setSeamlessLogin(false)
				.setSeamlessError(seamlessResult?.error ?? false)

			if (!seamlessResult?.error) {
				return seamlessResult
			}

			resultError = seamlessError

			const statusCode2 = Number(seamlessResponse.status)

			if (statusCode2 === 401 && !customParams.externalRequest) {
				try {
					yield put(AuthActions.logOut())
				} catch (e) {
					// console.error(e)
					// clear error
				}

				// Re auth mobile app (anonymous)
				AuthService.getInstance().authCallback()

				resultError.message = 'Авторизация истекла, войдите заново'
			}
		}

		const customError = new Error(
			resultError ? (resultError.message || JSON.stringify(resultError)) : 'Неизвестная ошибка',
		)

		customError.messageData = response

		throw customError
	}

	AuthService.getInstance().setSeamlessError(false)

	return result
}

/**
 * Try renew auth token and repeat request
 *
 * @param {SearchQuery} dataProvider
 * @param {string} resultEndpoint
 *
 * @return {object}
 */
function* renewToken(dataProvider, resultEndpoint) {
	const accessToken = yield getAccessToken()
	const renewSearchQuery = DataProvider
		.buildQuery()
		.addRequestOptions({
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}, true)
		.addBody({
			refresh_token: yield getRefreshToken(),
		}, true)
		.addCustomParams({
			saveAuth: true,
		}, true)

	const result = yield AuthApi.renewToken(renewSearchQuery)

	// Check response errors
	if (result?.error || !result?.response?.headers) {
		result.error = true

		return result
	}

	const newAccessToken = yield getAccessToken()
	dataProvider.addRequestOptions({
		headers: {
			Authorization: `Bearer ${newAccessToken}`,
		},
	}, true)

	return yield callApiEndpoint(resultEndpoint, dataProvider.getRequestOptions())
}
