import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import { INTL_ACTIONS } from './actionTypes'

const intlReducer = (state, action) => {
	switch (action.type) {
		case INTL_ACTIONS.SET_LANG:
			return Object.assign({}, state, {
				locale: action.payload,
			})

		case INTL_ACTIONS.SET_LANG_MESSAGES:
			return {
				...state,
				messages: action.payload,
			}

		case INTL_ACTIONS.SET_IS_RELOAD:
			return {
				...state,
				isReload: action.payload,
			}

		default:
			return { ...state }
	}
}

const persistConfig = {
	key: 'intl',
}

export default PersistHelper.persist(persistConfig, intlReducer)
