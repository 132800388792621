import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { COUNTER_ACTION } from './actionTypes'
import { CounterApi } from './api'

/**
 *  Get Counter
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* getCounter(action) {
	yield defaultAction(action, CounterApi.getCounter)
}

export default [
	takeLatest(COUNTER_ACTION.GET_COUNTER, getCounter),
]
