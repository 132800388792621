import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import LoginForm from '../../../../../global/LoginForm/index.store'
import styles from './styles.module.scss'

export const LoginButton = ({ className, textButton }) => {
	const [isActive, setIsActive] = useState(false)
	const onClose = useCallback(() => setIsActive(false), [])
	return (
		<>
			<button
				type="button"
				className={`${styles.buttonSignIn} ${className}`}
				onClick={() => setIsActive(true)}
			>
				<p>{textButton}</p>
			</button>
			<LoginForm isActive={isActive} onClose={onClose} />
		</>
	)
}

LoginButton.propTypes = {
	className:  PropTypes.string,
	textButton: PropTypes.string,
}
LoginButton.defaultProps = {
	className:  '',
	textButton: '',
}
