import React, { useState } from 'react'
import PropsTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { useIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import { User } from '../../../models'
import { ClickButton } from './ClickButton'
import { ConfirmButton } from './ConfirmButton'
import { LogoSubscription } from './LogoSubscription'
import styles from './styles.module.scss'
import { PaymentList } from './PaymentList'
import Icon from '../Icon'

const AddKarmaButton = ({
	karma,
	size,
	buttonPosition,
	setModalSubscription,
	isSubscription,
	onCloseDrawer,
	isOpenDrawer,
	stateUser,
	scrollIntoView,
}) => {
	const user = User.create(stateUser)
	const [isActive, setIsActive] = useState(false)
	const { isMobile } = useDeviceDetect()
	const fullScreen = isMobile
	const intl = useIntl()

	const onCloseDialog = () => {
		setIsActive(false)
		setModalSubscription(false)
	}
	const setSubscription = () => {
		setModalSubscription(true)
		setIsActive(true)
	}
	const setActive = () => {
		setIsActive(true)
	}
	const showSubscriptions = () => {
		onCloseDialog()
		if (isOpenDrawer) onCloseDrawer(false)
	}
	const setScroll = () => {
		showSubscriptions()
		scrollIntoView(true)
	}
	return (
		<>
			<ClickButton
				buttonPosition={buttonPosition}
				setSubscription={setSubscription}
				karma={karma}
				size={size}
				setActive={setActive}
				user={user}
			/>
			<Dialog open={isActive} onClose={onCloseDialog} fullScreen={fullScreen}>
				<div className={styles.dialogWrapper}>
					<div className={styles.buttonClose}>
						<button type="button" onClick={onCloseDialog}>
							<Icon icon="closeIcon" color="rgba(43, 43, 43, 0.6)" />
						</button>
					</div>
					<LogoSubscription isSubscription={isSubscription} showSubscriptions={showSubscriptions} />
					<PaymentList isSubscription={isSubscription} />
					<ConfirmButton isSubscription={isSubscription} setSubscription={setSubscription} />
					{isSubscription ?
						<div className={styles.troubleLinks}>
							<span>
								{' '}
								{intl.formatMessage({ id: 'subscription_rules' })
									.replace(intl.formatMessage({ id: 'read_more' }), '')}
								<Link to="/faq" onClick={setScroll}>{intl.formatMessage({ id: 'read_more' })}</Link>
							</span>
						</div> :
						<div className={styles.troubleLinks}>
							<a href="mailto:support@darom.by" target="_blank" rel="noopener noreferrer">
								{intl.formatMessage({ id: 'report_problem' })}
							</a>
						</div>}
				</div>
			</Dialog>
		</>
	)
}

AddKarmaButton.propTypes = {
	karma:                PropsTypes.number,
	stateUser:            PropsTypes.object,
	size:                 PropsTypes.number,
	buttonPosition:       PropsTypes.string,
	isOpenDrawer:         PropsTypes.bool,
	isSubscription:       PropsTypes.bool,
	setModalSubscription: PropsTypes.func,
	onCloseDrawer:        PropsTypes.func,
	scrollIntoView:       PropsTypes.func,

}
AddKarmaButton.defaultProps = {
	karma:                0,
	size:                 24,
	buttonPosition:       'left',
	isSubscription:       false,
	isOpenDrawer:         false,
	stateUser:            {},
	setModalSubscription: () => {},
	onCloseDrawer:        () => {},
	scrollIntoView:       () => {},
}
export default withRouter(AddKarmaButton)
