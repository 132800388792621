import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after'
import { Provider } from "react-redux"
import {
	configureStore,
} from 'reduxm/store'
import * as serviceWorker from 'serviceWorker'
import routes from './routing'
import Layout from './containers/Layout/index.store'
import './main.scss'

const preloadedState = getSerializedData('preloaded_state')
const { store } = configureStore(preloadedState)
const messages = require('assets/locale.json')

const renderApp = () => {
	ensureReady(routes).then(data => hydrate(
		<BrowserRouter>
			<Provider store={store}>
				<Layout messages={messages}>
					<After data={data} routes={routes} />
				</Layout>
			</Provider>
		</BrowserRouter>,
		document.getElementById('root')
	))
}

// ensureReady(routes).then(data => hydrate(
// 	<BrowserRouter>
// 		<Provider store={store}>
// 			<Layout messages={messages}>
// 				<After data={data} routes={routes} />
// 			</Layout>
// 		</Provider>
// 	</BrowserRouter>,
// 	document.getElementById('root')
// ))

renderApp()
// If you want your site to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
	module.hot.accept('./routing', renderApp)
	// module.hot.accept()
}