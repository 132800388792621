import React from 'react'
import IcoMoon from 'react-icomoon'
import PropTypes from 'prop-types'

const iconSet = require('./selection.json')

const Icon = ({ ...props }) => <IcoMoon iconSet={iconSet} {...props} />

Icon.propTypes = {
	icon:      PropTypes.string,
	size:      PropTypes.string,
	color:     PropTypes.string,
	className: PropTypes.string,
}
Icon.defaultProps = {
	icon:       '',
	size:      '24px',
	color:     '#000000',
	className: '',
}

export default Icon
