import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { CITIES_ACTION } from './index'
import { CitiesApi } from './api'

function* getCities(action) {
	yield defaultAction(action, CitiesApi.getCities)
}

function* getCity(action) {
	yield defaultAction(action, CitiesApi.getCity)
}

export default [
	takeLatest(CITIES_ACTION.GET_CITIES, getCities),
	takeLatest(CITIES_ACTION.GET_CITY, getCity),
]
