import { PageWrapper } from 'components/PageWrapper'
import React from 'react'
import styles from './styles.module.scss'

// ToDo add to locale file content and title for this page
const NotFound = () => {

	return (
		<PageWrapper title={'Ошибка 404. Страница не найдена!'}>
			<div className={styles.wrapper}>
				<div>The Page You Were Looking For Was Not Found</div>
			</div>
		</PageWrapper>
	)
}

export default NotFound
