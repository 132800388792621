import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECT_ACTION } from './actionTypes'

const persistConfig = {
	key:       'project',
	whitelist: [
		PROJECT_ACTION.REFERRER,
		PROJECT_ACTION.GEO_STATE,
	],
}

export default PersistHelper.persist(persistConfig,
	RequestActionHelper.createReducerActions(Object.values(PROJECT_ACTION)))
