import {
	DispatchSelector,
	// StateSelector,
} from 'reduxm/modules/selectors'
import { setLang } from 'reduxm/redux-saga/modules/intl/actionCreators'

const mapStateToProps = state => ({
	intlState: state.intl,
})
const mapDispatchToProps = {
	getUser:       DispatchSelector.user.getUser,
	getCategories: DispatchSelector.categories.getCategories,
	setLang,
}

export {
	mapDispatchToProps,
	mapStateToProps,
}
