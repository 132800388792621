import { takeLatest } from '@redux-saga/core/effects'
import { put } from 'redux-saga/effects'
import { HierarchicalService } from 'services/HierarchicalService'
import { defaultAction } from '../../helpers/defaultActions'
import { CategoriesActions } from './actionCreators'
import { CategoriesApi } from './api'
import { CATEGORIES_ACTION } from './actionTypes'

function* getCategory(action) {
	yield defaultAction(action, CategoriesApi.getCategory, null, null, function* (response) {
		HierarchicalService.getInstance().setCategories(response?.result)
		const result  = response?.result
		yield put(CategoriesActions.setCategories(result))
	})
}
export default [
	takeLatest(CATEGORIES_ACTION.GET, getCategory),
]
