import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import storage from '@kakadu-dev/base-frontend-helpers/storage/AsyncStorage'
import { persistReducer } from 'redux-persist'
import { USER_ACTION } from './actionTypes'

const persistConfig = {
	key:       'users',
	// storage,
	whitelist: [
		USER_ACTION.CURRENT,
	],
}

export default PersistHelper.persist(persistConfig,
	RequestActionHelper.createReducerActions(Object.values(USER_ACTION)))
// export default persistReducer(persistConfig, RequestActionHelper.createReducerActions(Object.values(USER_ACTION)))
