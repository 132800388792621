import PropTypes from 'prop-types'
import React from 'react'
import ButtonImg from '../../../assets/images/buttonUp.svg'
import styles from './styles.module.scss'

export const ScrollArrow = ({ scrollRef }) => {
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const scrollBottom = () => {
		scrollRef.current.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<div className={styles.scroll}>
			<button type="button" className={styles.scrollButton} onClick={scrollTop}>
				<img className={styles.image} src={ButtonImg} alt="Вверх" />
			</button>
			<button type="button" className={`${styles.scrollButton} ${styles.scrollBottom}`} onClick={scrollBottom}>
				<img className={styles.image} src={ButtonImg} alt="Вниз" />
			</button>
		</div>
	)
}

ScrollArrow.propTypes = {
	scrollRef:   PropTypes.object,
}

ScrollArrow.defaultProps = {
	scrollRef:   {},
}
