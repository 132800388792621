import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '../../../global/Spinner'
import { LoginPanel } from './LoginPanel'
import UserPanel from './UserPanel/index.store'
import styles from './styles.module.scss'
import { User } from '../../../../models'

export const Panel = ({ UserState, isFetching }) => {
	const [isLogined, setIsLogined] = useState(false)
	const authLogined = new User(UserState).isExist()

	useEffect(() => {
		if (authLogined && UserState.result !== null) setIsLogined(true)
		else setIsLogined(false)
	}, [UserState, authLogined])

	const viewPanel = () => {
		if (!isLogined) return <LoginPanel />
		return <UserPanel />
	}

	return (
		<div className={styles.buttonGroup}>
			{ isFetching ?
				<Spinner isFetching className={styles.spinner} /> :
				viewPanel()}
		</div>
	)
}

Panel.propTypes = {
	UserState:  PropTypes.object,
	isFetching: PropTypes.bool,
}
Panel.defaultProps = {
	UserState:  {},
	isFetching: false,
}
