import { callApi } from '../../api'

const getCities = searchQuery => callApi('/site/cities', searchQuery)

const getCity = searchQuery => {
	return callApi('/site/city', searchQuery)
}

export const CitiesApi = {
	getCities,
	getCity,
}
