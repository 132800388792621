import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * UserPage model class
 */
class User extends BaseModel {
	/**
	 * Create Service instance
	 *
	 * @param {Object} object
	 *
	 * @returns {User}
	 */
	static create(object) {
		return super.create(object)
	}

	/**
	 * Create Service list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<User>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map customers
	 *
	 * @param {Array.<object>} objects
	 * @param {function(User, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get first name
	 *
	 * @returns {string}
	 */
	getUsername = () => this.model.username

	/**
	 * Get Email
	 *
	 * @returns {string}
	 */
	getEmail = () => this.model.email

	/**
	 * Get phone
	 *
	 * @returns {string}
	 */
	getPhone = () => this.model.phone

	/**
	 * Get karma
	 *
	 * @returns {number}
	 */
	getKarma = () => this.model.karma

	/**
	 * Get positive reviews
	 *
	 * @returns {number}
	 */
	getPositiveReviews = () => this.model.positiveReviews

	/**
	 * Get city
	 *
	 * @returns {JSON}
	 */
	getCity = () => this.model.city

	/**
	 * Get avatar
	 *
	 * @returns {string}
	 */
	getAvatar = () => this.model.avatar

	/**
	 * Get referral code
	 *
	 * @return {string}
	 */
	getReferralCode = () => this.model.referral_code

	/**
	 * Get geo
	 *
	 * @return {JSON}
	 */
	getGeo = () => this.model.geo

	/**
	 * Get distance
	 *
	 * @return {number}
	 */
	getDistance = () => this.model.distance

	/**
	 * Get rating
	 *
	 * @return {number}
	 */
	getRating = () => this.model.rating

	/**
	 * Get params
	 *
	 * @return {number}
	 */
	getParams = () => this.model.params

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default User
