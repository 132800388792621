import PropsTypes from 'prop-types'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LogoSale from '../../../../assets/images/sale_give.jpg'
import Im_1 from '../../../../assets/images/subscription/Im_1.png'
import Im_2 from '../../../../assets/images/subscription/Im_2.png'
import Im_3 from '../../../../assets/images/subscription/Im_3.png'
import Im_4 from '../../../../assets/images/subscription/Im_4.png'
import iconInfo from '../../../../assets/images/iconInfo.svg'
import styles from './styles.module.scss'
import './styleCarousel.scss'

const images = [
	{
		id:          1,
		image:       Im_1,
		title:       <FormattedMessage id="subscribe_info_title_1" />,
		description: <FormattedMessage id="subscribe_info_desc_1" />,
	},
	{
		id:          2,
		image:       Im_2,
		title:       <FormattedMessage id="subscribe_info_title_2" />,
		description: <FormattedMessage id="subscribe_info_desc_2" />,
	},
	{
		id:          3,
		image:       Im_3,
		title:       <FormattedMessage id="subscribe_info_title_3" />,
		description: <FormattedMessage id="subscribe_info_desc_3" />,
	},
	{
		id:          4,
		image:       Im_4,
		title:       <FormattedMessage id="subscribe_info_title_4" />,
		description: <FormattedMessage id="subscribe_info_desc_4" />,
	},
]

export const LogoSubscription = ({
	isSubscription,
	showSubscriptions,
}) => (
	isSubscription ?
		<div className={styles.container}>
			<span className={styles.title}>
				<FormattedMessage id="subscriptions_enable" />
				<Link to="/subscriptions" onClick={showSubscriptions}>
					<img src={iconInfo} alt="info" />
				</Link>
			</span>
			<Carousel
				showThumbs={false}
				showStatus={false}
				showIndicators
				infiniteLoop
				swipeable
				autoPlay
				interval="5000"
				transitionTime="1000"
				className="customStyles"
			>
				{images.map(image => (
					<div key={image.id} className={styles.wrapper}>
						<div key={image.id} className={styles.wrapperImage}>
							<img src={image.image} alt="slider" />
						</div>
						<div className={styles.wrapperText}>
							<h2>{image.title}</h2>
							<span>{image.description}</span>
						</div>

					</div>))}
			</Carousel>
		</div> :
		<div>
			<div className={styles.logo}>
				<img src={LogoSale} alt="oops" />
			</div>
			<div className={styles.tittle}>
				<FormattedMessage id="get_karma_slogan" />
			</div>
		</div>

)
LogoSubscription.propTypes = {
	isSubscription:    PropsTypes.bool,
	showSubscriptions: PropsTypes.func,
}
LogoSubscription.defaultProps = {
	isSubscription:    PropsTypes.bool,
	showSubscriptions: PropsTypes.func,
}
