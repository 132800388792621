import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import Icon from '../Icon'
import promoPercent from '../../../assets/images/promoPercent.svg'

const AsideLinks = ({ links, isUserLinks, onClose }) => {
	const linkTo = useCallback(view => ({
		pathname: '/im',
		state:    view,
	}), [])

	return (
		<div className={styles.wrapper}>
			{links.map(l => (
				<div key={l.id}>
					<Link to={isUserLinks ? linkTo(l.to) : l.to} onClick={onClose}>
						{l.icon === 'promoPercent' ?
							<img src={promoPercent} alt="процент" /> :
							<Icon icon={l.icon} size="24px" color="rgba(43, 43, 43, 0.95)" />}
						<span style={l.font}>{l.title}</span>
					</Link>
				</div>
			))}
		</div>
	)
}
AsideLinks.propTypes = {
	links:       PropTypes.array,
	isUserLinks: PropTypes.bool,
	onClose:     PropTypes.func,
}
AsideLinks.defaultProps = {
	isUserLinks: false,
	onClose:     () => null,
	links:       [],
}

export default AsideLinks
