import { id_categories } from 'constants'

export class HierarchicalService
{
	/**
	 * @type {HierarchicalService}
	 */
	static myInstance = null

	/**
	 * @type {Array.<Object>}
	 */
	all = {
		categories: {},
		filtered: {}
	}


	/**
	 * Get instance
	 *
	 * @return {HierarchicalService}
	 */
	static getInstance() {
		if (this.myInstance === null) {
			this.myInstance = new HierarchicalService()
		}
		return this.myInstance
	}

	static toTranslit = text => {
		return text.replace(/([а-яё])|([\s_-])|([^a-z\d])/gi, (all, ch, space, words, i) =>
		{
			if (space || words) {
				return space ? '_' : ''
			}
			const code = ch.charCodeAt(0)

			// eslint-disable-next-line no-nested-ternary
			const index = code == 1025 || code == 1105 ? 0 :
				code > 1071 ? code - 1071 : code - 1039
			const t = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh',
				'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p',
				'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh',
				'shch', '', 'y', '', 'e', 'yu', 'ya'
			]
			return t[index]
		})
	}

	isCategory = i => i.hidden && i.is_selectable && !i.is_filterable && !i.sorting

	isSorting = i => i.sorting && !i.is_selectable && !i.is_filterable &&
		i.id !== id_categories.STORIES &&
		i.id !== id_categories.GIVE_AWAY

	isFilter = i => !i.hidden && !i.sorting && !i.is_selectable && !i.is_filterable &&
		i.id !== id_categories.STORIES && i.id !== id_categories.THINGS_GIVEN

	/**
	 * Set categories
	 *
	 * @param {Array.<Object>} categories
	 *
	 * @return {undefined}
	 */
	setCategories(categories) {
		if (!categories.length) {
			return
		}

		categories.map(category => {
			this.all[HierarchicalService.toTranslit(category.title)] = { ...category }
			// if (category.is_selectable) newSelect.push(category)
			// if (this.isSorting(category)) newSort.push(category)
			// if (this.isFilter(category)) newFilter.push(category)
		})

		// console.log('categories', this.categories)
	}

	getByTranslit = text => {
		return this.categories.find(cat => cat.transcription === text)
	}
}

