import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import appStoreButton from '../../../assets/images/appStoreButton.svg'
import googlePlayButton from '../../../assets/images/googlePlayButton.svg'
import Huawei from '../../../assets/images/Huawei.svg'
import LogoIcon from '../../../assets/images/Login_image.png'
import { socialNetworkLink } from '../../../constants'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import Icon from '../Icon'
import { GrCode } from '../QrCode'
import styles from './styles.module.scss'

export const DialogAppButton = ({
	obj,
	setIsOpen,
	isOpen,
	lotImg,
}) => {
	const { isMobile } = useDeviceDetect()
	const intl = useIntl()
	const onClose = () => setIsOpen(false)

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					overflowY:       'unset',
					maxWidth:        '840px',
					boxShadow:       'none',
				},
			}}
		>

			<div className={styles.wrapper}>
				{!isMobile &&
				<GrCode className={obj.styles === 'pickUp' ?
					styles.qrCodeDownloadPickUp :
					styles.qrCodeDownload}
				/>}
				<div className={styles.buttonClose}>
					<button type="button" onClick={onClose}>
						<Icon icon="closeIcon" size="24px" color="rgba(43, 43, 43, 0.6)" />
					</button>
				</div>
				{obj.styles === 'pickUp' ?
					<div
						className={styles.image}
						style={{
							backgroundImage:      `url(${lotImg})`,
							backgroundRepeat:     'no-repeat',
							backgroundPosition:   'center',
							backgroundSize:       'cover',
							borderTopRightRadius: '16px',
							borderTopLeftRadius:  '16px',
						}}
					>
						{/* <img src={lotImg} alt="" /> */}
					</div> :
					<div className={styles.image}>
						<img src={LogoIcon} alt="" />
					</div>}
				<div className={styles.title}>{obj.tittle}</div>
				<div className={styles.subTittle}>
					{intl.formatMessage({ id: 'use_app' })}
				</div>
				<div className={styles.btnGroup}>
					<div>
						<button type="button">
							<a href={socialNetworkLink.googleStore}>
								<img alt="googlePlay" src={googlePlayButton} className={styles.imgButton} />
							</a>
						</button>
					</div>

					<div>
						<button type="button">
							<a href={socialNetworkLink.appStore}>
								<img alt="appStore" src={appStoreButton} className={styles.imgButton} />
							</a>
						</button>
					</div>
					<div>
						<a href={socialNetworkLink.huawei} className={styles.huawaiButton}>
							<button type="button" className={styles.imgButton}>
								<img alt="Huawei" src={Huawei} className={styles.huawei} />
							</button>
						</a>
					</div>
				</div>
			</div>

		</Dialog>
	)
}
DialogAppButton.propTypes = {
	setIsOpen: PropTypes.func,
	obj:       PropTypes.object,
	isOpen:    PropTypes.bool,
	lotImg:    PropTypes.string,
}

DialogAppButton.defaultProps = {
	setIsOpen: () => null,
	obj:       {},
	isOpen:    false,
	lotImg:    '',
}
