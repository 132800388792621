import React from 'react'
import { useIntl } from 'react-intl'
import { useDeviceDetect } from '../../../../../helpers/useDeviceDetect'
import { DownloadAppButton, textString } from '../../../../global/DownloadAppButton'
import styles from '../styles.module.scss'
import { LoginButton } from './LoginButton'
import { MenuButton } from './MenuButton'

export const LoginPanel = () => {
	const { isLessIPadMini } = useDeviceDetect()
	const intl = useIntl()
	return (
		<>
			{!isLessIPadMini &&
				<>
					<DownloadAppButton obj={textString.addLot} className={styles.buttonAdd} />
					<LoginButton textButton={intl.formatMessage({ id: 'login' })} />
				</>}
			<MenuButton />
		</>
	)
}
