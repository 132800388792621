import PropsTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Carousel } from 'react-responsive-carousel'
import styles from '../styles.module.scss'

const textButton = [
	{ id:    1,
		text:  <FormattedMessage id="free_10_karma" /> },
	{ id:    2,
		text:   <FormattedMessage id="subscriptions_enable" /> },
]

export const ConfirmButton = ({ isSubscription, setSubscription }) => (
	<div>
		<div className={styles.continue}>
			<button type="button">
				{isSubscription ? <FormattedMessage id="continue_key" /> : <FormattedMessage id="karma_get" />}
			</button>
		</div>
		{!isSubscription &&
			<div className={styles.continue}>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
				<div
					className={styles.continueColor}
					role="button"
					onClick={setSubscription}
				>
					<Carousel
						showArrows={false}
						showThumbs={false}
						showStatus={false}
						showIndicators={false}
						infiniteLoop
						autoPlay
						interval="4000"
						transitionTime="800"
						axis="vertical"
					>
						{textButton.map(item => (
							<div key={item.id} className={styles.textButton}>
								{item.text}
							</div>))}
					</Carousel>
				</div>
			</div>}
	</div>
)
ConfirmButton.propTypes = {
	isSubscription:        PropsTypes.bool,
	setSubscription: PropsTypes.func.isRequired,
}
ConfirmButton.defaultProps = {
	isSubscription:        false,
}
