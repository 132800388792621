import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { setSearchString } from '../../../../../reduxm/redux-saga/modules/base/actionsCreators'

import UserPanel from './index'

const mapStateToProps = state => ({
	userState: StateSelector.user.getUser(state),
})
const mapDispatchToProps = {
	onLogout: DispatchSelector.auth.logOut,
	setUser:  DispatchSelector.user.setUser,
	setSearchString,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel)
