import { connect } from 'react-redux'
import { StateSelector } from '../../../reduxm/modules/selectors'
import { scrollIntoView, setModalSubscription } from '../../../reduxm/redux-saga/modules/base/actionsCreators'
import AddKarmaButton from './index'

const mapStateToProps = state => ({
	stateUser:      StateSelector.user.getUser(state),
	isSubscription:     state.base.isSubscription,
})
const mapDispatchToProps = {
	setModalSubscription,
	scrollIntoView,
}
export default connect(mapStateToProps, mapDispatchToProps)(AddKarmaButton)
