import { styled } from '@mui/material/styles'
import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Popover from '@mui/material/Popover'
import { setCookie } from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import { User } from '../../../models'
import Icon from '../Icon'
import styles from './styles.module.scss'
import russia from '../../../assets/images/languages/russia.svg'
import us from '../../../assets/images/languages/us.svg'
import arrowDown from '../../../assets/images/arrowDown.svg'
import arrowUp from '../../../assets/images/arrowUp.svg'

const flags = {
	ru: russia,
	en: us,
	pl: russia,
}

const languages = [
	{
		text:   'Русский',
		locale: 'ru',
	},
	{
		text:   'English',
		locale: 'en',
	},
	{
		text:   'Polska',
		locale: 'pl',
	},
]

const SuccessPopover = styled(Popover)(() => ({
	'& .MuiPopover-paper': {
		boxShadow: '0 0 16px rgba(43, 43, 43, 0.25)',
	},
}))

export const LanguagesSwitcher = ({
	intlState,
	setLang,
	setIsOpenDrawer,
	setReload,
	userState,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const t = languages.find(language => language.locale === intlState.locale)
	const intl = useIntl()
	const open = Boolean(anchorEl)
	const user = User.create(userState)

	const handleClick = e => setAnchorEl(e.currentTarget)

	const handleClose = () => setAnchorEl(null)

	const getLanguage = async (e, lang) => {
		setAnchorEl(null)
		setLang(lang)
		setIsOpenDrawer(false)
		setCookie('locale', lang, true)
		setReload(true)
	}

	return (
		<div className={styles.container} style={{ paddingLeft: user.isExist() ? '19px' : '16px' }}>
			<img src={flags?.[intlState.locale]} alt="flag" className={styles.imgLocale} />
			<span className={styles.label}>
				{intl.formatMessage({ id: 'language_settings' })}
				:
			</span>
			<>
				<button type="button" onClick={handleClick} className={styles.button}>
					<span>
						{t.text}
					</span>
					<img src={open ? arrowUp : arrowDown} className={styles.imgArrow} alt="arrow" />
				</button>
				<SuccessPopover
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					disableEnforceFocus
					anchorOrigin={{
						vertical:   'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical:   'top',
						horizontal: 'left',
					}}
				>
					<div className={styles.popoverWrapper}>
						{languages.map(language => {
							const isActive = intlState.locale === language.locale
							return (
								<div
									className={[styles.popover, isActive ? styles.popoverActive : ''].join(' ')}
									onClick={e => getLanguage(e, language.locale)}
									role="none"
									key={language.locale}
								>
									<span>{language.text}</span>
									{isActive && <Icon icon="filterSelectIcon" size="16px" />}
								</div>)
						})}
					</div>
				</SuccessPopover>
			</>
		</div>
	)
}

LanguagesSwitcher.propTypes = {
	intlState:       PropTypes.object,
	userState:       PropTypes.object,
	setLang:         PropTypes.func,
	setIsOpenDrawer: PropTypes.func,
	setReload:       PropTypes.func,

}
LanguagesSwitcher.defaultProps = {
	intlState:       {},
	userState:       {},
	setLang:         () => null,
	setIsOpenDrawer: () => null,
	setReload:       () => null,

}
