import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '../../Icon'
import styles from './styles.module.scss'

export const ClickButton = ({ buttonPosition, setSubscription, karma, size, setActive }) => {
	const intl = useIntl()
	const withStyle = s => ({
		width:           s,
		height:          s,
		borderRadius:    s / 2,
		backgroundColor: '#FE3F55',
		border:          'none',
		padding:         0,
	})
	 const setButton = button => {
		 switch (button) {
			 case 'left': {
				 return (
					 <>
						 <button type="button" onClick={setActive} style={withStyle(size)}>
							 <Icon icon="plus" size="12px" color="#FFFFFF" />
						 </button>
						 <span>{`${karma} ${intl.formatMessage({ id: 'karma' })}`}</span>
					 </>
				 )
			 }
			 case 'subscription': {
				 return (
					 <button
						 type="button"
						 className={styles.buttonSubscription}
						 onClick={setSubscription}
					 >
						 {/* <span>{user.getSubscription() ? 'Подписка' : 'Включить подписку'}</span> */}
						 <span>{intl.formatMessage({ id: 'subscriptions_enable' })}</span>
					 </button>
				 )
			 }

			 default:
				 return (
					 <div className={[styles.karmaContainerInfo, styles.karmaBackground].join(' ')}>
						 <button type="button" onClick={setActive} style={withStyle(size)}>
							 <Icon icon="plus" size="12px" color="#FFFFFF" />
						 </button>
						 <span>{`${karma} ${intl.formatMessage({ id: 'karma' })}`}</span>
					 </div>
				 )
		 }
	 }
	return (
		<div className={styles.karmaContainer}>
			{setButton(buttonPosition)}
		</div>)
}

ClickButton.propTypes = {
	buttonPosition:        PropTypes.string,
	setSubscription:       PropTypes.func.isRequired,
	size:                  PropTypes.number,
	karma:                 PropTypes.number,
	setActive:             PropTypes.func,
}

ClickButton.defaultProps = {
	buttonPosition:        '',
	size:                  24,
	karma:                 'left',
	setActive:             () => null,
}
