import { callApi } from '../../api'

const getPages = searchQuery => callApi('/site/list-static-page', searchQuery)

const getPage = searchQuery => {
	return callApi('/site/page', searchQuery)
}

export const DynamicPageApi = {
	getPages,
	getPage,
}
