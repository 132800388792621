import { callApi } from '../../api'
import { ENVIRONMENT_DEV } from '../../config'

export const timeUpdateCounter = 30

const getCounter = searchQuery => {
	searchQuery.cacheResponse(timeUpdateCounter - 1, ENVIRONMENT_DEV)
	return callApi('/lot/finish-count', searchQuery)
}

export const CounterApi = {
	getCounter,
}
