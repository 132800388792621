import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Star } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'
import styles from './styles.module.scss'

export const UserRating = ({ rating, fontSize, className, isPromo }) => {
	const StyledIcon = withStyles({
		root: {
			fontSize,
		},
	})(props => <SvgIcon {...props} />)

	const componentRating = () => {
		const ratings = []
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < 5; i++) {
			if (i < rating) {
				ratings.push(
					<StyledIcon className={isPromo ? styles.promo : styles.isRating} key={i} component={Star} />,
				)
			} else ratings.push(<StyledIcon className={styles.fill} key={i} component={Star} />)
		}
		return ratings
	}

	return (
		<div className={className}>
			{componentRating()}
		</div>
	)
}
UserRating.propTypes = {
	rating:    PropTypes.number,
	fontSize:  PropTypes.string,
	className: PropTypes.string,
	isPromo:   PropTypes.object,
}
UserRating.defaultProps = {
	rating:    0,
	fontSize:  '1.25rem',
	className: '',
	isPromo:   {},
}
