import { callApi } from '../../api'

const getItem = searchQuery => callApi('/lot/index/', searchQuery)

// const getItems = searchQuery => callApi('/v2/lot/feed', searchQuery)
const getItems = searchQuery => callApi('/lot/feed', searchQuery)
// раскомитеть home.js ItemPage.js

// const getSpacerItems = searchQuery => callApi('/v2/lot/feed', searchQuery)
const getSpacerItems = searchQuery => callApi('/lot/feed', searchQuery)

// const getSliderItems = searchQuery => callApi('/v2/lot/feed', searchQuery)
const getSliderItems = searchQuery => callApi('/lot/feed', searchQuery)

const likeItem = searchQuery => {
	searchQuery.addRequestOptions({
		method: 'POST',
	}, true)
	return callApi('/lot/favorite', searchQuery)
}

const getItemsQuantity = searchQuery => {
	searchQuery.cacheResponse(60, true)
	return callApi('/lot/feed-count', searchQuery)
}

const getItemsCount = searchQuery => {
	searchQuery.cacheResponse(60, true)
	return callApi('/lot/feed-count', searchQuery)
}

const getItemsFavorite = searchQuery => {
	searchQuery.cacheResponse(60, true)
	return callApi('/lot/feed', searchQuery)
}

const getItemsUser = searchQuery => {
	searchQuery.cacheResponse(60, true)
	return callApi('/user/feed', searchQuery)
}

const getSearchItems = searchQuery => callApi('/lot/search', searchQuery)

const getSearchTags = searchQuery => callApi('/lot/search-tags', searchQuery)

const getLotInfo = searchQuery => callApi('/lot/info', searchQuery)

export const ItemsApi = {
	getItem,
	getItems,
	likeItem,
	getItemsQuantity,
	getItemsUser,
	getItemsFavorite,
	getSearchItems,
	getLotInfo,
	getSearchTags,
	getSpacerItems,
	getSliderItems,
	getItemsCount,
}
