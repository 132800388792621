import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { DYNAMIC_PAGE_ACTION } from './index'
import { DynamicPageApi } from './api'

function* getPages(action) {
	yield defaultAction(action, DynamicPageApi.getPages)
}

function* getPage(action) {
	yield defaultAction(action, DynamicPageApi.getPage)
}

export default [
	takeLatest(DYNAMIC_PAGE_ACTION.GET_PAGES, getPages),
	takeLatest(DYNAMIC_PAGE_ACTION.GET_PAGE, getPage),
]
