import {
	AuthActions,
	AuthSelectors,
} from '../redux-saga/modules/auth'
import {
	UserActions,
	UserSelectors,
} from '../redux-saga/modules/user'
import {
	CounterActions,
	CounterSelectors,
} from '../redux-saga/modules/counter'
import {
	ItemsActions,
	ItemsSelectors,
} from '../redux-saga/modules/items'
import {
	CategoriesActions,
	CategoriesSelectors,
} from '../redux-saga/modules/categories'
import {
	ProjectActions,
	ProjectSelectors,
} from '../redux-saga/modules/project'
import {
	DynamicPageActions,
	DynamicPageSelectors,
} from '../redux-saga/modules/dynamicPage'
import {
	CitiesActions,
	CitiesSelectors,
} from '../redux-saga/modules/cities'

export const StateSelector = {
	auth:                AuthSelectors,
	user:                UserSelectors,
	counter:             CounterSelectors,
	items:               ItemsSelectors,
	categories:          CategoriesSelectors,
	project:             ProjectSelectors,
	dynamicPage:         DynamicPageSelectors,
	cities:              CitiesSelectors,
}
export const DispatchSelector = {
	auth:                AuthActions,
	user:                UserActions,
	counter:             CounterActions,
	items:               ItemsActions,
	categories:          CategoriesActions,
	project:             ProjectActions,
	dynamicPage:         DynamicPageActions,
	cities:              CitiesActions,
}
