import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SagasHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/SagasHelper'

/**
 * Method for fix the action
 *
 * @param {Object} params
 *
 * @return {undefined}
 */
export function* defaultAction(...params) {
	const [action, ...otherParams] = params

	const { payload } = action

	const searchQuery = DataProvider.getSearchQueryBody(payload)
	const nextAction = {
		...action,
		payload: searchQuery,
	}

	yield SagasHelper.defaultAction(nextAction, ...otherParams)
}
