import { connect } from 'react-redux'
import {
	DispatchSelector, StateSelector,
} from 'reduxm/modules/selectors'
import { setQueryParams, setSearchString } from '../../../../reduxm/redux-saga/modules/base/actionsCreators'

import SearchForm from './index'

const mapStateToProps = state => ({
	searchString:   state.base.searchString,
	searchTags:     StateSelector.items.searchTags(state),
	getQueryParams: state.base.queryParams,
})
const mapDispatchToProps = {
	getSearchItems:  DispatchSelector.items.getSearchItems,
	getSearchTags:   DispatchSelector.items.getSearchTags,
	setQueryParams,
	setSearchString,
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)
