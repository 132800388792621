import AuthReducer from '../redux-saga/modules/auth/reducer'
import UserReducer from '../redux-saga/modules/user/reducer'
import CounterReducer from '../redux-saga/modules/counter/reducer'
import ItemsReducer from '../redux-saga/modules/items/reducer'
import CategoriesReducer from '../redux-saga/modules/categories/reducer'
import ProjectReducer from '../redux-saga/modules/project/reducer'
import DynamicPageReducer from '../redux-saga/modules/dynamicPage/reducer'
import CitiesReducer from '../redux-saga/modules/cities/reducer'
import baseReducer from '../redux-saga/modules/base/reducer'
import intlReducer from '../redux-saga/modules/intl/reducer'
import queryParamsReducer from '../redux-saga/modules/queryParams/reducer'

export const allReducers = {
	auth:                AuthReducer,
	user:                UserReducer,
	counter:             CounterReducer,
	items:               ItemsReducer,
	categories:          CategoriesReducer,
	project:             ProjectReducer,
	dynamicPage:         DynamicPageReducer,
	cities:              CitiesReducer,
	base:                baseReducer,
	queryParams:         queryParamsReducer,
	intl:                intlReducer,
}
