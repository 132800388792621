import { defaultTitle } from 'constants'
import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

export const PageWrapper = ({ children, title }) => {

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{children}
		</>
	)
}

PageWrapper.propTypes = {
	children: PropTypes.node,
	title:    PropTypes.string,
}
PageWrapper.defaultProps = {
	children: {},
	title:    defaultTitle,
}