export const googleCredentials = {
	GOOGLE_API_KEY: 'AIzaSyDxWiZxY3Z9Ht8NwoOtV_a_wTCdvOQ2TUE',
}

export const defaultLang = 'ru'

export const defaultTitle = 'Give Away'

export const supportedLanguages = {
	en: 'English',
	ru: 'Русский',
	pl: 'Polski'
}

export const statuses = {
	STATUS_WAITING_RESERVE:    8,
	STATUS_WAITING_KARMA:      4,
	STATUS_CLOSED:             3,
	STATUS_FINISH_PARTICIPANT: 6,
	STATUS_MEETING:            2,
	STATUS_IDLE:               1,
	STATUS_REMOVED:            0,
	STATUS_MODERATION:         11,
}

export const id_categories = {
	ALL:          0,
	FOR_YOU:      9,
	STORIES:      17,
	THINGS_GIVEN: 20,
	GIVE_AWAY:    22,
}

export const promoStatuses = {
	STATUS_PROMO_ACTIVE:  'active',
	STATUS_PROMO_USED:    'used',
	STATUS_PROMO_EXPIRED: 'expired',
}

export const currencyTypes = {
	TYPE_KARMA:  'karma',
	TYPE_MONEY:  'money',
}

export const types = {
	TYPE_FIXED:     'fixed',
	TYPE_AUCTION:   'auction',
	TYPE_PROMOTION:  'promotion',
}

export const socialNetworkLink = {
	huawei:      'https://appgallery.huawei.com/#/app/C102781943?source=darom.by',
	appStore:	   'https://appstore.com',
	googleStore: 'https://play.google.com',
}

export const pagesAliases = [
	'about',
	'faq',
	'police',
]