import {
	applyMiddleware,
	combineReducers,
	createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { allReducers } from './modules/redux'
import {SagaManager} from "./sagaManager"

export function configureStore(preloadedState) {
	const sagaMiddleware = createSagaMiddleware()

	const middlewares = [
		sagaMiddleware,
		// Notifications,
	]

	const devTools = process.env.NODE_ENV === "production"
		? applyMiddleware(...middlewares)
		: composeWithDevTools(applyMiddleware(...middlewares))

	const store = createStore(
		combineReducers({...allReducers}),
		preloadedState,
		devTools
	)

	const persistor = persistStore(store)

	let sagaTask = sagaMiddleware.run(SagaManager)
	store.cancelSagas = () => {
		sagaTask.cancel()
	}

	if (module.hot) {
		module.hot.accept(['./sagaManager'], () => {
			const NewSagaManager = require('./sagaManager').SagaManager
			sagaTask.cancel()
			sagaTask.toPromise().then(() => {
				sagaTask = sagaMiddleware.run(NewSagaManager)
			})
		})
	}
	return { store, persistor }
}
