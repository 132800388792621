import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import FacebookAuth from 'react-facebook-auth'
import GoogleLogin from 'react-google-login'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import FbImage from '../../../assets/images/Facebook.svg'
import GoogleImage from '../../../assets/images/Google.svg'
import VkImage from '../../../assets/images/VK.svg'
import { FACEBOOK_ID, REDIRECT_URL, VK_ID } from '../../../reduxm/redux-saga/config'
import Icon from '../Icon'
import Logo from '../Logo'
import { Spinner } from '../Spinner'
import styles from './styles.module.scss'

const MyFacebookButton = ({ onClick }) => {
	const intl = useIntl()
	return (
		<button type="button" className={styles.buttonFB} onClick={onClick}>
			<p>
				<img src={FbImage} alt={intl.formatMessage({ id: 'facebook' })} />
			</p>
			<span>{intl.formatMessage({ id: 'facebook' })}</span>
		</button>
	)
}
MyFacebookButton.propTypes = {
	onClick: PropTypes.func,
}
MyFacebookButton.defaultProps = {
	onClick: () => null,
}

export const LoginForm = ({
	onLogin,
	isActive,
	onClose,
	isFetching,
	history,
	// copyTextRef,
	// depLink,
	// onCopy,
}) => {
	const [isLink, setIsLink] = useState(true)
	const [referral_code, setReferralCode] = useState('')
	const intl = useIntl()

	const facebookCallback = ({
		id,
		accessToken,
	}) => {
		const searchQuery = {
			identity:      id,
			service_token: accessToken,
			service:       'facebook',
		}
		onLogin(searchQuery)
		history.push('/')
	}

	const googleCallback = ({
		tokenId,
		googleId,
	}) => {
		const searchQuery = {
			identity:      googleId,
			service_token: tokenId,
			service:       'google',
			referral_code,
		}
		onLogin(searchQuery)
		history.push('/')
	}

	const vkCallback = () => {
		window.location.href = `https://oauth.vk.com/authorize?client_id=${VK_ID}&display=popup&
		redirect_uri=${REDIRECT_URL}&scope=offline&response_type=code&v=5.126`
	}

	const handleReferralCode = useCallback(event => {
		setReferralCode(event.target.value)
	}, [])

	return (
		<Dialog
			open={isActive}
			onClose={onClose}
			PaperProps={{
				style: {
					overflowY: 'unset',
					maxHeight: 'none',
				},
			}}
		>
			<div className={styles.wrapper}>
				<div className={styles.buttonClose}>
					<button type="button" onClick={onClose}>
						<Icon icon="closeIcon" color="rgba(43, 43, 43, 0.95)" />
					</button>
				</div>
				<div className={styles.logoImage}>
					<Logo size="64px" />
				</div>
				<div className={styles.logoText}>{intl.formatMessage({ id: 'give_away' }).toUpperCase()}</div>
				<div className={styles.title}>{intl.formatMessage({ id: 'login_site' })}</div>
				{isFetching ? <Spinner isFetching className={styles.spinner} /> :
					(
						<>
							{isLink ?
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events
								<div
									className={styles.invite}
									onClick={() => setIsLink(false)}
									role="button"
									tabIndex={0}
								>
									<span>{intl.formatMessage({ id: 'input_promo_hint' })}</span>
								</div> :
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events
								<div className={styles.inviteWrapper}>
									{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
									<div
										className={styles.invite}
										onClick={() => setIsLink(false)}
										role="button"
										tabIndex={0}
									>
										<span>
											{intl.formatMessage({ id: 'format_input_promo' }).replace('%s', '')}
										</span>
										<input
											type="text"
											onChange={handleReferralCode}
											value={referral_code}
										/>
									</div>
									{referral_code.length && referral_code.length !== 8 ?
										<span className={styles.errorCode}>
											{intl.formatMessage({ id: 'error_code' })}
										</span> :
										null}
								</div>}
							<div className={styles.buttonWrapper}>
								<button type="button" onClick={vkCallback} className={styles.buttonVK}>
									<p>
										<img src={VkImage} alt={intl.formatMessage({ id: 'vkontakte' })} />
									</p>
									<span>{intl.formatMessage({ id: 'vkontakte' })}</span>
								</button>

								<FacebookAuth
									callback={facebookCallback}
									appId={FACEBOOK_ID}
									component={MyFacebookButton}
								/>

								<GoogleLogin
									clientId="49951157130-9fgnq4mlj66qvd276c80o1srbfufo7go.apps.googleusercontent.com"
									render={renderProps => (
										<button
											type="button"
											className={styles.buttonGoogle}
											onClick={renderProps.onClick}
										>
											<p>
												<img
													src={GoogleImage}
													alt={intl.formatMessage({ id: 'google_plus' })}
												/>
											</p>
											<span>{intl.formatMessage({ id: 'google_plus' })}</span>
										</button>
									)}
									onSuccess={googleCallback}
									cookiePolicy="single_host_origin"
								/>
							</div>
							<div className={styles.footer}>
								<Link to="/privacy">Privacy </Link>
								<span> & </span>
								<Link to="/terms"> Terms of Use</Link>
							</div>
						</>
					)}
			</div>
		</Dialog>
	)
}
LoginForm.propTypes = {
	onLogin:    PropTypes.func,
	isActive:   PropTypes.bool,
	onClose:    PropTypes.func,
	isFetching: PropTypes.bool,
	history:    PropTypes.object,
}

LoginForm.defaultProps = {
	onLogin:    () => null,
	isActive:   false,
	onClose:    () => null,
	isFetching: false,
	history:    null,
}
