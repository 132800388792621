import { put, takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { UserActions } from './actionCreators'
import { USER_ACTION } from './actionTypes'
import { UserApi } from './api'

/**
 *  Get User
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* getUser(action) {
	yield defaultAction(action, UserApi.getUser)
}

/**
 *  Create DeepLink
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* createDeepLink(action) {
	yield defaultAction(action, UserApi.createDeepLink)
}

function* getUserUpDate(action) {
	yield defaultAction(action, UserApi.getUserUpDate, null, null, afterUpdateUser)
}

function* afterUpdateUser(response) {
	yield put(UserActions.setUser(response?.result))
}

export default [
	takeLatest(USER_ACTION.CURRENT, getUser),
	takeLatest(USER_ACTION.CREATE_DEEP_LINK, createDeepLink),
	takeLatest(USER_ACTION.UPDATE_USER, getUserUpDate),
]
