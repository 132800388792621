import { put, takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { AUTH_ACTION } from './actionTypes'
import { AuthApi } from './api'
import { removeAuthTokens } from '../../api'
import { UserActions } from '../user'

/**
 *  login
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* login(action) {
	yield defaultAction(action, AuthApi.login, null, null, function* (response) {
		yield put(UserActions.setUser(response?.result?.user))
	})
}

/**
 *  logout
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* logout(action) {
	yield defaultAction(action, AuthApi.logout, null, null, function* () {
		yield removeAuthTokens()
		yield put(UserActions.setUser(null))
	})
}

/**
 *  renewToken
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* renewToken(action) {
	yield defaultAction(action, AuthApi.renewToken)
}

export default [
	takeLatest(AUTH_ACTION.LOGIN, login),
	takeLatest(AUTH_ACTION.LOG_OUT, logout),
	takeLatest(AUTH_ACTION.RENEW_TOKEN, renewToken),
]
