import AuthSaga from '../redux-saga/modules/auth/sagas'
import UserSaga from '../redux-saga/modules/user/sagas'
import CounterSaga from '../redux-saga/modules/counter/sagas'
import ItemsSaga from '../redux-saga/modules/items/sagas'
import CategoriesSaga from '../redux-saga/modules/categories/sagas'
import DynamicPageSaga from '../redux-saga/modules/dynamicPage/sagas'
import CitiesSaga from '../redux-saga/modules/cities/sagas'

export default [
	...AuthSaga,
	...UserSaga,
	...CounterSaga,
	...ItemsSaga,
	...CategoriesSaga,
	...DynamicPageSaga,
	...CitiesSaga,
]
