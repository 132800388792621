import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useDeviceDetect } from '../../../../../helpers/useDeviceDetect'
import { User } from '../../../../../models'
import AddKarmaButton from '../../../../global/AddKarmaButton/index.store'
import { DownloadAppButton, textString } from '../../../../global/DownloadAppButton'
import { NotificationButton } from '../../../../global/Notificationbutton'
import styles from './styles.module.scss'
import { UserButton } from './UserButton'

const UserPanel = ({
	userState,
	onLogout,
	history,
	setSearchString,
	setUser,
}) => {
	const user = User.create(userState)
	const {
		isMobile,
		isLessIPadMini,
	} = useDeviceDetect()

	const onConfirmLogout = useCallback(() => {
		onLogout()
		history.push('/')
	}, [onLogout, history])

	return (
		<div className={styles.wrapper}>
			{!isLessIPadMini &&
			<DownloadAppButton
				obj={textString.addLot}
				className={styles.buttonAdd}
				isMobile={isMobile}
			/>}
			{!isLessIPadMini &&
			<div className={styles.notifications}>
				<NotificationButton />
			</div>}
			<div className={isLessIPadMini ? styles.wrapperMobile : styles.wrapperUser}>
				{!isLessIPadMini && <AddKarmaButton karma={user.getKarma()} size={40} />}
				<UserButton
					user={user}
					onLogout={onConfirmLogout}
					setSearchString={setSearchString}
					setUser={setUser}
				/>
			</div>
		</div>
	)
}
UserPanel.propTypes = {
	userState:       PropTypes.object,
	onLogout:        PropTypes.func,
	history:         PropTypes.object,
	setSearchString: PropTypes.func,
	setUser:         PropTypes.func,
}
UserPanel.defaultProps = {
	userState:       {},
	onLogout:        () => null,
	history:         {},
	setSearchString: () => null,
	setUser:         () => null,
}
export default withRouter(UserPanel)
