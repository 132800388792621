import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import Logo from '../../global/Logo'
import { ScrollArrow } from '../../global/ScrollArrow'
import styles from './styles.module.scss'
import { downloadLinks, downloadLinksMobile, socialLinks } from './data'

// eslint-disable-next-line no-unused-vars
export const Footer = ({ stateDynamicPages }) => {
	const { result } = stateDynamicPages
	const dinamicPages = result?.filter(item => item.alias !== 'terms' && item.alias !== 'privacy')
	const intl = useIntl()
	const { isIPadMini, isNotDesktop, isLessIPadMini } = useDeviceDetect()
	const scrollRef = useRef(null)
	const location = useLocation()
	const isShow = location.pathname === '/' || location.pathname.includes('city')

	const showDownLoadButton = () => {
		if (!isNotDesktop) {
			return (
				<div className={styles.download}>
					<span>{intl.formatMessage({ id: 'download_app' })}</span>
					{downloadLinks.map(link => (
						<a href={link.href} key={link.id}>
							<img alt="googlePlay" src={link.src} className={link.className} />
						</a>
					))}
				</div>)
		}
		if (isLessIPadMini) {
			return (
				<div className={styles.download}>
					{downloadLinksMobile.map(link => (
						<a href={link.href} key={link.id}>
							<img alt="googlePlay" src={link.src} className={link.className} />
						</a>
					))}
				</div>
			)
		}
		return null
	}
	return (
		<footer className={styles.wrapper} ref={scrollRef}>
			<div className={styles.siteMap}>
				<div className={styles.social}>
					{!isIPadMini &&
					<Link to="/" className={styles.logoBlock}>
						<Logo size="48px" />
						<span>{intl.formatMessage({ id: 'give_away' })}</span>
					</Link>}
					<span className={styles.socialLink}>
						{socialLinks.map(l => (<a key={l.id} href={l.href} className={l.className}>{l.icon}</a>))}
					</span>
				</div>
				{showDownLoadButton()}
				<div className={styles.links}>
					<ul>
						{
							dinamicPages &&
							dinamicPages?.map(page => (
								<li key={page.alias}>
									<Link to={page.alias}>{page.title}</Link>
								</li>))
						}
					</ul>
				</div>
				{isShow && <ScrollArrow scrollRef={scrollRef} />}
			</div>
			{!isNotDesktop && (
				<div className={styles.wrapperCopyrighting}>
					<div className={styles.copyrighting}>
						<span className={styles.rights}>GiveAway Global, Inc. © 2020. All Rights Reserved.</span>
						<div className={styles.terms}>
							<Link to="/terms">Terms of Conditions</Link>
							<Link to="/privacy">Privacy Police</Link>
						</div>
					</div>
				</div>)}
		</footer>
	)
}
Footer.propTypes = {
	stateDynamicPages: PropTypes.object,
}

Footer.defaultProps = {
	stateDynamicPages: {},
}
