import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { CITIES_ACTION } from './actionTypes'

const persistConfig = {
	key:       'cities',
	whitelist: [
		CITIES_ACTION.GET_CITIES,
	],
}

export default PersistHelper.persist(persistConfig,
	RequestActionHelper.createReducerActions(Object.values(CITIES_ACTION)))
