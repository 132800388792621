import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { CATEGORIES_ACTION } from './actionTypes'

const persistConfig = {
	key:       'categories',
	whitelist: [
		CATEGORIES_ACTION.GET,
	],
}

export default PersistHelper.persist(persistConfig,
	RequestActionHelper.createReducerActions(Object.values(CATEGORIES_ACTION)))
