import { MODAL_SUBSCRIPTION, QUERY_PARAMS, SCROLL, SEARCH_STRING } from './actionTypes'

export const setModalSubscription = isSubscription => ({
	type: MODAL_SUBSCRIPTION,
	isSubscription,
})
export const setSearchString = searchString => ({
	type: SEARCH_STRING,
	searchString,
})
export const setQueryParams = params => ({
	type: QUERY_PARAMS,
	params,
})
export const scrollIntoView = isScroll => ({
	type: SCROLL,
	isScroll,
})
