/* eslint-disable no-nested-ternary */
import PropsTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './styles.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const elArray = [
	{
		id:      1,
		karma:   10,
		text:    <FormattedMessage id="karma" />,
		benefit: '',
		percent: '',
		cost:    '0.39 $/',
	},
	{
		id:      2,
		karma:   80,
		text:    <FormattedMessage id="karma" />,
		benefit: <FormattedMessage id="sale_purchase_label" />,
		percent: '64%',
		cost:    '0.30 $/',
	},
	{
		id:      3,
		karma:   200,
		text:    <FormattedMessage id="karma" />,
		benefit: <FormattedMessage id="sale_purchase_label" />,
		percent: '63%',
		cost:    '0.23 $/',
	},
]
const dataSubscription = [
	{
		id:      1,
		karma:   12,
		text:    <FormattedMessage id="months_many" />,
		benefit: <FormattedMessage id="sale_purchase_label" />,
		percent: '52%',
		cost:    '4.82 $/',
	},
	{
		id:                2,
		karma:             3,
		text:              <FormattedMessage id="day" />,
		benefit:           <FormattedMessage id="free" />,
		percent:           '',
		cost:              <FormattedMessage id="trial_desc" />,
		costActive:        <FormattedMessage id="trial_desc" />,
		costActivePercent: '9.97 $/',
	},
	{
		id:      6,
		karma:   200,
		text:    <FormattedMessage id="months_many" />,
		benefit: <FormattedMessage id="sale_purchase_label" />,
		percent: '45%',
		cost:    '06,29 $/',
	},
]

export const PaymentList = ({ isSubscription }) => {
	const data = isSubscription ? dataSubscription : elArray
	const [isActive, setIsActive] = useState(2)
	const intl = useIntl()
	const onCardClick = e => {
		setIsActive(parseInt(e.currentTarget.id, 10))
	}
	const styleBenefit = index => {
		if (index !== 0 || isSubscription) return `${styles.benefitNotActive} ${styles.benefit}`
		return `${styles.benefit}`
	}
	const styleCountKarma = index => {
		if (index === 1 && isSubscription) return `${styles.countKarma} ${styles.countKarmaSecond}`
		if (index !== 0 || isSubscription) return `${styles.countKarma} ${styles.countKarmaBenefit}`
		return `${styles.countKarma}`
	}
	const styleCost = index => {
		if (index === 0 && !isSubscription) return `${styles.cost} ${styles.costFirst}`
		if (index === 1 && isSubscription) return `${styles.cost} ${styles.costSecond}`
		return `${styles.cost}`
	}
	const styleWrapperBenefit = index => {
		if (index === 0 && !isSubscription) return `${styles.wrapperBenefit} ${styles.wrapperBenefitFirst}`
		if (index === 1 && isSubscription) return `${styles.wrapperBenefit} ${styles.wrapperBenefitSecond}`
		return `${styles.wrapperBenefit}`
	}
	const stylePercent = index => {
		if (index === 0 && !isSubscription) return `${styles.percent}`
		return `${styles.percentFirst}`
	}
	const drawCard = () => data.map((item, index) => (
		<div key={item.id} className={styles.cardWrapper}>
			<button
				className={`${styles.card} ${(item.id === isActive ? styles.cardActive : '')}`}
				id={item.id}
				onClick={onCardClick}
				type="button"
			>
				<div className={styleCountKarma(index)}>
					{item.karma}
				</div>
				<div className={styles.text}>
					{item.text}
				</div>
				<div className={styleWrapperBenefit(index)}>
					<div className={styleBenefit(index)}>
						<span>{item.benefit}</span>
						<span className={stylePercent(index)}>{item.percent}</span>
					</div>
					<div className={styleCost(index)}>
						{item.id === isActive && item.costActive ?
							<div>
								{`${intl.formatMessage({ id: 'trial_desc' })}
								 ${item.costActivePercent}${intl.formatMessage({ id: 'month_price_format' })
			.replace('%s/', '')}`}
							</div> :
							<div>
								{`${item.cost.props ? `${intl.formatMessage({ id: item.cost.props.id })}...` :
									item.cost}${isSubscription ?
									!item.cost.props ?
										intl.formatMessage({ id: 'month_price_format' }).replace('%s/', '') :
										''										 :
									intl.formatMessage({ id: 'piece_price_format' })
										.replace('%s/', '')}`}
							</div>}

					</div>
				</div>
			</button>
		</div>
	))

	return (
		<div className={styles.wrapper}>
			{drawCard()}
		</div>
	)
}
PaymentList.propTypes = {
	isSubscription: PropsTypes.bool,

}
PaymentList.defaultProps = {
	isSubscription: false,

}
