import React, { useCallback, useState } from 'react'
import { Drawer } from '@material-ui/core'
import styles from './styles.module.scss'
import { AsidePanel } from './AsidePanel'
import Icon from '../../../../../global/Icon'

export const MenuButton = () => {
	const [isActive, setIsActive] = useState(false)

	const onClose = useCallback(() => {
		setIsActive(false)
	}, [])

	return (
		<>
			<button className={styles.buttonMenu} type="button" onClick={() => setIsActive(true)}>
				<div>
					<Icon icon="menu" size="16px" color="rgba(43, 43, 43, 0.6)" />
				</div>
			</button>
			<Drawer
				anchor="right"
				open={isActive}
				onClose={onClose}
			>
				<AsidePanel onClose={onClose} />
			</Drawer>
		</>
	)
}
