import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgress, Dialog, withStyles } from '@material-ui/core'
import styles from './styles.module.scss'

const DialogStyled = withStyles({
	paper: {
		background: 'transparent',
		transition: 'none',
		boxShadow:  'none',
	},
})(Dialog)

/**
 * Render spinner
 *
 * @param {boolean} isFetching
 * @param {boolean} isFullScreen
 * @param {string}  className
 * @param {number} size
 *
 * @return {*}
 * @constructor
 */
export const Spinner = ({ isFetching, isFullScreen, className, size }) => (
	isFetching &&
	(isFullScreen ?
		<DialogStyled open fullScreen={isFullScreen}>
			<div className={styles.default}>
				<CircularProgress style={{ color: '#FE3F55' }} size={size} />
			</div>
		</DialogStyled> :
		<div className={className || styles.default}>
			<CircularProgress style={{ color: '#FE3F55' }} size={size} />
		</div>)
)

Spinner.propTypes = {
	isFetching:   PropTypes.bool,
	isFullScreen: PropTypes.bool,
	size:         PropTypes.number,
	className:    PropTypes.string,
}

Spinner.defaultProps = {
	isFetching:   false,
	isFullScreen: false,
	size:         40,
	className:    '',
}
