import { getCookie } from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import { defaultLang } from 'constants'
import React, {
	useEffect,
	useMemo,
} from 'react'
import PropTypes from 'prop-types'
import ReactNotification from 'react-notifications-component'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'
import {
	IntlProvider,
} from 'react-intl'
import { GeoService } from 'services/GeoService'
import { Header } from 'components/Layout/Header'
import { Footer } from 'components/Layout/Footer'
import styles from './styles.module.scss'
import { mapDispatchToProps } from './index.props'

const searchQuery = DataProvider.buildQuery()
	.cacheResponse(3600, true)

const Layout = ({
	children,
	getUser,
	getCategories,
	intlState,
	messages,
}) => {

	const { locale } = useMemo(() => intlState, [intlState])

	useEffect(() => {
		getCategories(searchQuery)
	},[getCategories, locale])

	useEffect(() => {
		getUser()
	}, [getUser])

	return (
		<IntlProvider
			locale={locale}
			messages={messages[locale]}
			defaultLocale={defaultLang}
		>
			<Helmet>
				<title>Title</title>
			</Helmet>
			{/* <ToastContainer autoClose={5000} /> */}
			{/* <ReactNotification /> */}
			<div className={styles.wrapper}>
				<Header />
				<div className={styles.main}>
					{children}
				</div>
				<Footer />
			</div>
		</IntlProvider>
	)
}

// Layout.getInitialProps = SSRComponentHelper.initialProps(async (dispatcher, { store, isSSR }) => {
// 	if (isSSR) {
// 		try {
// 			const { getUser, getCategories } = dispatcher
// 			const city  = getCookie('city')
// 			console.log('city', city)
// 			// ToDo get user default city and category
//
// 			await Promise.all([
// 				getUser(),
// 				getCategories(searchQuery),
// 			])
// 		}
// 		catch (e) {
// 			// console.log('SSR Layout exception', e)
// 		}
// 	}
// }, mapDispatchToProps)


Layout.propTypes = {
	children:      PropTypes.node.isRequired,
	intlState:     PropTypes.object,
	getUser:       PropTypes.func,
	getCategories: PropTypes.func,
	messages:      PropTypes.object,
}

Layout.defaultProps = {
	intlState:     {},
	getUser:       () => null,
	getCategories: () => null,
	messages:      {},
}

export { Layout }
