import { connect } from 'react-redux'
import { setModalSubscription } from '../../../reduxm/redux-saga/modules/base/actionsCreators'

import { UserBlock } from './index'

const mapStateToProps = state => ({
	isSubscription:     state.base.isSubscription,
})
const mapDispatchToProps = {
	setModalSubscription,
}
export default connect(mapStateToProps, mapDispatchToProps)(UserBlock)
