import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { isFetching } from '../../../helpers/methods'
import { LoginForm } from './index'

const mapStateToProps = state => ({
	isFetching:      isFetching(
		StateSelector.auth.login(state),
	),
})

const mapDispatchToProps = {
	onLogin: DispatchSelector.auth.login,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
