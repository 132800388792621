import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTH_ACTION } from './actionTypes'

export const AuthActions = {
	login:           RequestActionHelper.getActionCreatorFetch(AUTH_ACTION.LOGIN),
	setLogin:        RequestActionHelper.getActionCreatorSuccess(AUTH_ACTION.LOGIN),
	logOut:          RequestActionHelper.getActionCreatorFetch(AUTH_ACTION.LOG_OUT),
	renewToken:      RequestActionHelper.getActionCreatorFetch(AUTH_ACTION.RENEW_TOKEN),
	renewTokenError: RequestActionHelper.getActionCreatorError(AUTH_ACTION.RENEW_TOKEN),
}
