import { ITEMS_ACTION } from './actionTypes'

export const ItemsSelectors = {
	getItem:           state => state.items[ITEMS_ACTION.GET_ITEM],
	getItems:          state => state.items[ITEMS_ACTION.GET_ITEMS],
	getSpacerItems:    state => state.items[ITEMS_ACTION.GET_SPACER_ITEMS],
	getSliderItems:    state => state.items[ITEMS_ACTION.GET_SLIDER_ITEMS],
	getMoreItems:      state => state.items[ITEMS_ACTION.GET_MORE_ITEMS],
	likeItem:          state => state.items[ITEMS_ACTION.LIKE_ITEM],
	getItemsQuantity:  state => state.items[ITEMS_ACTION.GET_ITEMS_QUANTITY],
	getItemsCount:     state => state.items[ITEMS_ACTION.GET_ITEMS_COUNT],
	getItemsBet:       state => state.items[ITEMS_ACTION.GET_ITEMS_BET],
	getItemsCreate:    state => state.items[ITEMS_ACTION.GET_ITEMS_CREATE],
	getItemsPromo:     state => state.items[ITEMS_ACTION.GET_ITEMS_PROMO],
	getItemsFavorite:  state => state.items[ITEMS_ACTION.GET_ITEMS_FAVORITE],
	getSearchItems:    state => state.items[ITEMS_ACTION.SEARCH_ITEMS],
	searchTags:        state => state.items[ITEMS_ACTION.SEARCH_TAGS],
	getLotInfo:        state => state.items[ITEMS_ACTION.GET_LOT_INFO],
	updateItem:        state => state.items[ITEMS_ACTION.UPDATE_ITEM],
}
