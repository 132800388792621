import { useEffect, useState } from 'react'

const breakpoints = {
	mobile:   460,
	iPadMini: 768,
	iPadPro:  1195,
	desktop:  1440,
}

const hasWindow = (typeof window !== 'undefined')

export const useDeviceDetect = () => {
	const [width, setWidth] = useState({ width: hasWindow ? window.innerWidth : null })
	const [height, setHeight] = useState({ height: hasWindow ? window.innerHeight : null })

	let isMobile = false
	let isIPadMini = false
	let isPadPro = false
	let isNotDesktop = false
	let isLessIPadMini = false
	if(hasWindow)
	{
		isMobile = width.width <= breakpoints.mobile
		isIPadMini =   width.width <= breakpoints.iPadMini && width.width >= breakpoints.mobile
		isPadPro =   width.width <= breakpoints.iPadPro && width.width >= breakpoints.iPadMini
		isNotDesktop = width.width <= breakpoints.iPadMini
		isLessIPadMini = width.width <= breakpoints.iPadMini
	}



	useEffect(() => {
		if(hasWindow) {
			const handleResize = () => {
				setWidth({ width: window.innerWidth })
				setHeight({ height: window.innerHeight })
			}

			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
		return () => null
	})

	return { isMobile, isIPadMini, isPadPro, isNotDesktop, isLessIPadMini, width, height }
}
