import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { StateSelector } from '../../../reduxm/modules/selectors'
import { setLang, setReload } from '../../../reduxm/redux-saga/modules/intl/actionCreators'
import { LanguagesSwitcher } from './index'

const mapStateToProps = state => ({
	intlState:       state.intl,
	isReload:       state.isReload,
	userState: StateSelector.user.getUser(state),
})
const mapDispatchToProps = {
	setLang,
	setReload,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguagesSwitcher))
