import { connect } from 'react-redux'
import {
	StateSelector,
} from 'reduxm/modules/selectors'
import { isFetching } from '../../../../helpers/methods'
import { Panel } from './index'

const mapStateToProps = state => ({
	UserState:  StateSelector.user.getUser(state),
	isFetching:      isFetching(
		StateSelector.auth.login(state),
	),
})

export default connect(mapStateToProps, null)(Panel)
