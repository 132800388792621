import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import bgImage from '../../../assets/images/Login_image.png'
import QRcode from '../../../assets/images/QRcode.svg'
import { promoStatuses, statuses } from '../../../constants'
import { DialogAppButton } from './DialogAppButton'
import { ShowPromoMoneyDialog } from './ShowPromoMoneyDialog'
import styles from './styles.module.scss'

export const textString = {
	addLot: {
		buttonText:  <FormattedMessage id="add_lot_button" />,
		tittle:     <FormattedMessage id="add_lot_app" />,
		download:   <FormattedMessage id="download_app" />,
		qr:         QRcode,
		is:         false,
	},
	pickUp: {
		buttonText: <FormattedMessage id="want_take" />,
		tittle:     <FormattedMessage id="take_lot_app" />,
		download:    <FormattedMessage id="download_app" />,
		img:        bgImage,
		qr:         QRcode,
		styles:     'pickUp',
		is:         false,
	},
	author: {
		tittle:   <FormattedMessage id="view_user_app" />,
		download:  <FormattedMessage id="download_app" />,
		styles:   'download',
		qr:       QRcode,
		is:       false,
	},
}

export const DownloadAppButton = ({
	buyNowPrice,
	nextBid,
	currencyPrefix,
	className,
	obj,
	author,
	promo,
	status,
	isLotMoney,
	isLotMoneyAuction,
	isObserve,
	isUserLot,
	categoryId,
	lotImg,
}) => {
	const [isOpen, setIsOpen] = useState(obj.is)
	const intl = useIntl()
	const isPromoActive = promo?.promotion_status === promoStatuses.STATUS_PROMO_ACTIVE
	const isPromoExpired = promo?.promotion_status === promoStatuses.STATUS_PROMO_EXPIRED
	const isPromoActiveAgain =  (promo?.promotion_status === promoStatuses.STATUS_PROMO_USED ||
		promo?.promotion_status === promoStatuses.STATUS_PROMO_EXPIRED) &&
	status === statuses.STATUS_IDLE

	const setButtonText = () => {
		if (status === statuses.STATUS_CLOSED) return intl.formatMessage({ id: 'search_add_to_waiting' }).toUpperCase()
		if (categoryId === 17) {
			return intl.formatMessage({ id: 'format_give_karma' })
				.replace('%s', '1')
				.toUpperCase()
		}
		return obj.buttonText
	}

	const setPromoButtonText = () => {
		if (status === 3) return intl.formatMessage({ id: 'search_add_to_waiting' }).toUpperCase()

		if (isPromoActive) {
			return intl.formatMessage({ id: 'view_promocode' }).toUpperCase()
		}
		if (isPromoActiveAgain) {
			return intl.formatMessage({ id: 'promotion_retry' }).toUpperCase()
		}
		if (isPromoExpired) {
			return intl.formatMessage({ id: 'promotion_expired_btn' }).toUpperCase()
		}
		return intl.formatMessage({ id: 'receive' }).toUpperCase()
	}

	const setContent = () => {
		if (isUserLot && status === statuses.STATUS_IDLE) {
			return (
				<>
					{' '}
					<button
						className={[className].join(' ')}
						type="button"
						onClick={() => setIsOpen(true)}
					>
						<p>{intl.formatMessage({ id: 'lot_promote' }).toUpperCase()}</p>
					</button>
					<ShowPromoMoneyDialog setIsOpen={setIsOpen} isUserLot={isUserLot} isOpen={isOpen} lotImg={lotImg} />
				</>

			)
		}
		if (isLotMoneyAuction) {
			return (
				<div className={[styles.wrapperMoneyAuction, isObserve ? styles.buttonMoneyAuction : ''].join(' ')}>

					<button
						className={[className].join(' ')}
						type="button"
						onClick={() => setIsOpen(true)}
					>
						<p>
							{`${intl.formatMessage({ id: 'bid_button_short' }).toUpperCase()}
							 ${currencyPrefix}${nextBid}`}
						</p>

					</button>
					<button
						className={[className, styles.money, styles.moneyAuction].join(' ')}
						type="button"
						onClick={() => setIsOpen(true)}
					>
						<p>
							{`${intl.formatMessage({ id: 'make_offer' }).toUpperCase()}
						 ${currencyPrefix}${buyNowPrice}`}
						</p>
					</button>
					<ShowPromoMoneyDialog
						setIsOpen={setIsOpen}
						isLotMoney={isLotMoney}
						isOpen={isOpen}
						isLotMoneyAuction={isLotMoneyAuction}
						lotImg={lotImg}
					/>
				</div>

			)
		}
		if (isLotMoney) {
			return (
				<>
					{' '}
					<button
						className={[className, styles.money].join(' ')}
						type="button"
						onClick={() => setIsOpen(true)}
					>
						<p>
							{intl.formatMessage({ id: 'buy_it_now' }).toUpperCase()}
						</p>
					</button>
					<ShowPromoMoneyDialog
						setIsOpen={setIsOpen}
						isLotMoney={isLotMoney}
						isOpen={isOpen}
						lotImg={lotImg}
					/>
				</>

			)
		}
		if (promo) {
			return (
				<>
					{' '}
					<button
						className={
							[className,
								styles.promo,
								promo.promotion_status === 'expired' &&
								!isPromoActiveAgain ? styles.promoExpired : ''].join(' ')
						}
						type="button"
						onClick={() => setIsOpen(true)}
					>
						<p>{setPromoButtonText()}</p>

					</button>
					<ShowPromoMoneyDialog
						setIsOpen={setIsOpen}
						isOpen={isOpen}
						lotImg={lotImg}
						isPromoActive={isPromoActive}
					/>
				</>
			)
		}
		return (
			<>
				{' '}
				<button
					className={className}
					type="button"
					onClick={() => setIsOpen(true)}
				>
					{obj.styles === 'download' ? author : <p>{setButtonText()}</p>}

				</button>
				<DialogAppButton
					setIsOpen={setIsOpen}
					obj={obj}
					isOpen={isOpen}
					lotImg={lotImg}
					setPromoButtonText={setPromoButtonText}
				/>
			</>

		)
	}

	return (
		<>
			{setContent()}
		</>

	)
}
DownloadAppButton.propTypes = {
	className:         PropTypes.string,
	currencyPrefix:    PropTypes.string,
	lotImg:            PropTypes.string,
	obj:               PropTypes.object,
	author:            PropTypes.object,
	categoryId:        PropTypes.number,
	status:            PropTypes.number,
	promo:             PropTypes.object,
	buyNowPrice:       PropTypes.number,
	nextBid:           PropTypes.number,
	isLotMoney:        PropTypes.bool,
	isLotMoneyAuction: PropTypes.bool,
	isObserve:         PropTypes.bool,
	isUserLot:         PropTypes.bool,
}
DownloadAppButton.defaultProps = {
	className:         '',
	currencyPrefix:    '',
	lotImg:            '',
	obj:               {},
	status:            1,
	author:            {},
	categoryId:        null,
	promo:             null,
	buyNowPrice:       0,
	nextBid:           0,
	isLotMoney:        false,
	isLotMoneyAuction: false,
	isObserve:         false,
	isUserLot:         false,
}
