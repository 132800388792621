import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { toQueryParams } from 'helpers/methods'
import styles from './styles.module.scss'
import { useCheckOutsideClick } from '../../../global/useCheckOutsideClick'
import search from '../../../../assets/images/search.png'

const SearchForm = ({ setSearchString, searchString, searchTags, getSearchTags, setQueryParams, getQueryParams }) => {
	const [isSearching, setIsSearching]   = useState(false)
	const [isTags, setIsTags]   = useState(false)
	const tagResult = searchTags?.result
	const searchTagsRef = useRef()
	const intl = useIntl()
	useCheckOutsideClick(searchTagsRef, isTags, setIsTags, isTags)

	const getTags = debounce(() => {
		if (searchString.length > 1) {
			const searchQuery = DataProvider.buildQuery()
			const queryLocation = { q: searchString }
			toQueryParams(searchQuery, queryLocation)
			getSearchTags(searchQuery)
		}
	}, 500)

	useEffect(() => {
		if (isTags) getTags()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchString])

	const startSearching = useCallback(() => {
		setIsSearching(true)
	}, [])

	const showSearchResults = useMemo(() => isSearching && searchString.length > 0,
		[isSearching, searchString])

	const clearSearching = useCallback(() => {
		setIsSearching(false)
		setSearchString('')
		setQueryParams({ ...getQueryParams?.result, filter: 0, category: 9 })
	}, [setSearchString, getQueryParams, setQueryParams])

	const clickTag = e => {
		e.preventDefault()
		const tagName = e.target.name
		setSearchString(tagName)
		setIsTags(false)
	}
	const handleInput = useCallback(event => {
		setSearchString(event.target.value)
		setIsTags(true)
	}, [setSearchString])

	return (
		<div className={styles.searchForm}>
			<div className={styles.searchButtonWrapper} role="none" onClick={startSearching}>
				<img src={search} className={styles.searchIcon} alt="search" />
				<input
					type="search"
					placeholder={intl.formatMessage({ id: 'search' })}
					onChange={handleInput}
					value={searchString}
					autoComplete="off"
				/>
				{showSearchResults &&
				<button type="button" className={styles.cancelButton} onClick={clearSearching}>
					<SvgIcon
						component={Cancel}
						fontSize="small"
						viewBox="0 0 26 26"
					/>
				</button>}
				{(isTags && (tagResult?.length > 0)) &&
				<div className={styles.searchTags} ref={searchTagsRef}>
					{tagResult.map((tag, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<div key={index} className={styles.searchTag}>
							<img src={search} className={styles.searchIcon} alt="search" />
							<button type="button" name={tag} onClick={clickTag}>{tag}</button>
						</div>
					))}
				</div>}
			</div>
		</div>
	)
}
SearchForm.propTypes = {
	setSearchString: PropTypes.func,
	searchString:    PropTypes.string,
	searchTags:      PropTypes.object,
	getSearchTags:   PropTypes.func,
	setQueryParams:  PropTypes.func,
	getQueryParams:  PropTypes.object,
}
SearchForm.defaultProps = {
	setSearchString: () => null,
	searchString:    '',
	searchTags:      {},
	getSearchTags:   () => null,
	setQueryParams:  () => null,
	getQueryParams:  {},
}
export default withRouter(SearchForm)
