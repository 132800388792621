import { all } from 'redux-saga/effects'
import modulesSagas from './modules/sagas'

function* SagaManager() {
	yield all([
		...modulesSagas,
	])
}

export {
	SagaManager
}
