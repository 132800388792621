import { put, select, takeLatest } from 'redux-saga/effects'
import { defaultAction } from '../../helpers/defaultActions'
import { ITEMS_ACTION } from './actionTypes'
import { ItemsSelectors } from './actionSelectors'
import { ItemsApi } from './api'
import { ItemsActions } from './actionCreators'

function* getItem(action) {
	yield defaultAction(action, ItemsApi.getItem)
}

/**
 *  Get Items
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* getItems(action) {
	yield defaultAction(action, ItemsApi.getItems)
}

function* getMoreItems(action) {
	yield defaultAction(action, ItemsApi.getItems, null, null, afterGetMoreItems)
}

function* afterGetMoreItems(response) {
	const { result: addList } = response
	const { result: { list: prevList } } = yield select(ItemsSelectors.getItems)
	const newArray = { list: [...prevList, ...addList]}
	yield put(ItemsActions.setItems(newArray))
}

function* getSpacerItems(action) {
	yield defaultAction(action, ItemsApi.getSpacerItems)
}

function* getSliderItems(action) {
	yield defaultAction(action, ItemsApi.getSliderItems)
}

function* likeItem(action) {
	yield defaultAction(action, ItemsApi.likeItem, null, null, afterLiked)
}

function* afterLiked(response) {
	const { result: changeObj } = response
	const { result: prevArray } = yield select(ItemsSelectors.getItems)
	const elIndex = prevArray.findIndex(x => x.id === changeObj.id)
	const newArray = [...prevArray.slice(0, elIndex), changeObj, ...prevArray.slice(elIndex + 1)]
	yield put(ItemsActions.setItems(newArray))
}

function* getItemsQuantity(action) {
	yield defaultAction(action, ItemsApi.getItemsQuantity)
}

function* getItemsCount(action) {
	yield defaultAction(action, ItemsApi.getItemsCount)
}

function* getItemsUser(action) {
	yield defaultAction(action, ItemsApi.getItemsUser)
}

function* getItemsFavorite(action) {
	yield defaultAction(action, ItemsApi.getItemsFavorite)
}

function* getSearchItems(action) {
	yield defaultAction(action, ItemsApi.getSearchItems, null, null, afterSearchItems)
}

function* afterSearchItems(response) {
	yield put(ItemsActions.setItems(response.result.items))
}

function* getMoreSearchItems(action) {
	yield defaultAction(action, ItemsApi.getSearchItems, null, null, afterGetMoreSearchItems)
}

function* afterGetMoreSearchItems(response) {
	const moreItems  = response.result.items
	const { result: prevItems } = yield select(ItemsSelectors.getItems)
	const newArray = [...prevItems, ...moreItems]
	yield put(ItemsActions.setItems(newArray))
}

function* getSearchTags(action) {
	yield defaultAction(action, ItemsApi.getSearchTags)
}

function* getLotInfo(action) {
	yield defaultAction(action, ItemsApi.getLotInfo)
}

function* updateItem(action) {
	yield defaultAction(action, ItemsApi.getItem, null, null, afterUpdateItem)
}

function* afterUpdateItem(response) {
	const { result: newItem } = response
	newItem.updatedStatus = 'Закончен'

	const { result: prevArray } = yield select(ItemsSelectors.getItems)
	const newArray = prevArray.map(item => (item.id !== newItem.id ? item : newItem))
	yield put(ItemsActions.setItems(newArray))
}

export default [
	takeLatest(ITEMS_ACTION.GET_ITEM, getItem),
	takeLatest(ITEMS_ACTION.GET_ITEMS, getItems),
	takeLatest(ITEMS_ACTION.GET_SPACER_ITEMS, getSpacerItems),
	takeLatest(ITEMS_ACTION.GET_SLIDER_ITEMS, getSliderItems),
	takeLatest(ITEMS_ACTION.GET_MORE_ITEMS, getMoreItems),
	takeLatest(ITEMS_ACTION.LIKE_ITEM, likeItem),
	takeLatest(ITEMS_ACTION.GET_ITEMS_QUANTITY, getItemsQuantity),
	takeLatest(ITEMS_ACTION.GET_ITEMS_COUNT, getItemsCount),
	takeLatest(ITEMS_ACTION.GET_ITEMS_BET, getItemsUser),
	takeLatest(ITEMS_ACTION.GET_ITEMS_CREATE, getItemsUser),
	takeLatest(ITEMS_ACTION.GET_ITEMS_PROMO, getItemsUser),
	takeLatest(ITEMS_ACTION.GET_ITEMS_FAVORITE, getItemsFavorite),
	takeLatest(ITEMS_ACTION.SEARCH_ITEMS, getSearchItems),
	takeLatest(ITEMS_ACTION.GET_LOT_INFO, getLotInfo),
	takeLatest(ITEMS_ACTION.UPDATE_ITEM, updateItem),
	takeLatest(ITEMS_ACTION.SEARCH_MORE_ITEMS, getMoreSearchItems),
	takeLatest(ITEMS_ACTION.SEARCH_TAGS, getSearchTags),
]
