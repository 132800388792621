import React from 'react'
import PropTypes from 'prop-types'
import LogoIcon from 'assets/images/logo64x64.png'
import LogoByKarmaIcon from 'assets/images/logoByKarma.svg'

const Logo = ({ size, isByKarma }) => (
	<>
		{isByKarma ?
			<img src={LogoByKarmaIcon} alt="" style={{ width: size, height: size }} /> :
			<img src={LogoIcon} alt="" style={{ width: size, height: size }} />}
	</>
)

Logo.propTypes = {
	size:      PropTypes.string,
	isByKarma: PropTypes.bool,
}
Logo.defaultProps = {
	size:      '64px',
	isByKarma: false,
}

export default Logo
