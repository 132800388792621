import React from 'react'
import { socialNetworkLink } from 'constants'
import Icon from '../../global/Icon'
import styles from './styles.module.scss'
import appGallery from '../../../assets/images/appGalleryButton.svg'
import appStoreButton from '../../../assets/images/appStoreButton.svg'
import googlePlayButton from '../../../assets/images/googlePlayButton.svg'
import huawei from '../../../assets/images/Huawei.svg'

export const siteMapLinks = [
	{ id: 0, to: '/about', title: 'О проекте' },
	{ id: 1, to: '/faq', title: 'FAQ' },
	{ id: 2, to: '/history', title: 'Истории' },
	{ id: 3, to: '/reviews', title: 'Отзывы' },
	{ id: 4, to: '/media', title: 'Пресса' },
	{ id: 5, to: '/feedback', title: 'Обратная связь' },
	{ id: 6, to: '/appstore', title: 'Установить приложение' },
	{ id: 7, to: '/contacts', title: 'Контакты' },
]

export const socialLinks = [
	{
		id:        0,
		href:      'https://www.facebook.com/',
		icon:      <Icon icon="facebook" color="#ffffff" size="16px" />,
		className: styles.socialFacebook,
	},
	{
		id:        1,
		href:      'https://twitter.com/',
		icon:      <Icon icon="twitter" color="#ffffff" size="16px" />,
		className: styles.socialTwitter,
	},
	{
		id:        2,
		href:      'https://www.instagram.com/',
		icon:      <Icon icon="instagram" color="#ffffff" size="16px" />,
		className: styles.socialInstagram,
	},
]

export const downloadLinks = [
	{
		id:        0,
		href:      socialNetworkLink.googleStore,
		src: 	     googlePlayButton,
		className: styles.downloadButton,

	},
	{
		id:        1,
		href:      socialNetworkLink.appStore,
		src: 	     appStoreButton,
		className: styles.downloadButton,
	},
	{
		id:        2,
		href:      socialNetworkLink.huawei,
		src: 	     appGallery,
		className: styles.downloadButton,
	},
]

export const downloadLinksMobile = [
	{
		id:        0,
		href:      socialNetworkLink.googleStore,
		src: 	     googlePlayButton,
		className: styles.downloadButton,

	},
	{
		id:        1,
		href:      socialNetworkLink.appStore,
		src: 	     appStoreButton,
		className: styles.downloadButton,
	},
	{
		id:        2,
		href:      socialNetworkLink.huawei,
		src: 	     huawei,
		className: styles.downloadButton,
	},
]
