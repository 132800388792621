import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import QRcode from '../../../assets/images/QRcode.svg'
import { DialogAppButton } from '../DownloadAppButton/DialogAppButton'
import Icon from '../Icon'
import styles from './styles.module.scss'

const param = {
	tittle:   <FormattedMessage id="get_info" />,
	download: <FormattedMessage id="download_app" />,
	qr:       QRcode,
	is:       false,
}

export const NotificationButton = ({ label }) => {
	const [isOpen, setIsOpen] = useState(param.is)

	const handleButton = e => {
		e.preventDefault()
		setIsOpen(true)
	}

	return (
		<div className={label ? styles.container : styles.containerHeader}>
			<button type="button" onClick={handleButton} className={styles.button}>
				<Icon icon="notificationsIcon" size="24px" />
				{label &&
				<span className={styles.label}>{label}</span>}
			</button>

			<DialogAppButton
				setIsOpen={setIsOpen}
				obj={param}
				isOpen={isOpen}
			/>
		</div>
	)
}

NotificationButton.propTypes = {
	label:            PropTypes.string,
}
NotificationButton.defaultProps = {
	label:            '',
}
